<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { warning } from "@/use/toast/warning";

export default {
  name: 'LeafletMap',
  props: {
    positions: {
      type: Array,
      required: true,
    }
  },
  mounted() {
    const planimetry = L.map(this.$refs.map, {
      crs: L.CRS.Simple,
      zoomControl: true,
      scrollWheelZoom: false,
      touchZoom: true,
      zoomAnimation: true,
      doubleClickZoom: false,
      boxZoom: false,
      dragging: true,
      trackResize: true,
    });
    planimetry.attributionControl.setPrefix('');
    // Used to load and display tile layers on the map
    L.tileLayer("", {
      attribution:
        'From Innovation Factory with &#9829;',
    }).addTo(planimetry);
    const planimetryImg = require('@/assets/images/planimetry.jpg'); // Import the image
    const imageBounds = [[0, 0], [45.27, 48.72]];
    const planimetryOverlay = L.imageOverlay(planimetryImg, imageBounds);

    planimetryOverlay.addTo(planimetry);
    setTimeout(() => {
      planimetry.fitBounds(imageBounds);
      planimetry.zoomIn(2);
    }, 2000);

    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: [25, 25],
        popupAnchor: [-25, -25]
      }
    });

    const iconImg = require('@/assets/icons/bullet.svg');
    const redBullet = new LeafIcon({ iconUrl: iconImg });
    const xOffset = 4.65;
    const yOffset = 5;
    const positionsByDevice = Object.values(
      this.positions.reduce((acc, { device_id, prescription, ...otherProps }) => {
        if (!acc[device_id]) {
          acc[device_id] = { device_id, prescriptions: [], ...otherProps };
        }
        acc[device_id].prescriptions.push(prescription);
        return acc;
      }, {})
    );

    positionsByDevice.forEach(element => {
      if (element.x && element.y) {

        L.marker([(element.y + yOffset), (element.x + xOffset)], { icon: redBullet })
          .bindPopup(this.createDomElement(element))
          .addTo(planimetry);
        }
      else {
        warning(`Posizione del tag al momento non disponibile!`);
      }
    });
  },
  methods: {
    createDomElement(element) {
      const container = document.createElement('div');

      // <strong><center><u>DETTAGLI</u></center><strong>
      const strongTitle = document.createElement('strong');
      const underline = document.createElement('u');
      underline.textContent = 'DETTAGLI';
      strongTitle.appendChild(underline);
      container.appendChild(strongTitle);
      strongTitle.appendChild(document.createElement('br'));

      // ${element.area}
      const areaStrong = document.createElement('strong');
      areaStrong.textContent = 'AREA: ';
      container.appendChild(areaStrong);
      container.appendChild(document.createTextNode(element.area));
      container.appendChild(document.createElement('br'));

      // ${element.device_name}
      const deviceStrong = document.createElement('strong');
      deviceStrong.textContent = 'TAG: ';
      container.appendChild(deviceStrong);
      container.appendChild(document.createTextNode(element.device_name));
      container.appendChild(document.createElement('br'));

      if (element.prescriptions.lenght) {
        container.appendChild(document.createTextNode('PRESCRIZIONI:'));
        container.appendChild(document.createElement('br'));

        // <strong>${element.prescriptions.join('<br>')}</strong>
        const prescriptionsStrong = document.createElement('strong');
        element.prescriptions.forEach(prescription => {
          prescriptionsStrong.appendChild(document.createTextNode(prescription));
          prescriptionsStrong.appendChild(document.createElement('br'));
        });
        container.appendChild(prescriptionsStrong);
      }

      return container.innerHTML;
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column justify-content-between">
    <div ref="map" class="leaflet-map"></div>
  </div>
</template>

<style scoped>
.leaflet-map {
  height: 90vh;
}

.leaflet-container {
  width: 100%;
}
</style>
