<template>
  <div class="card rounded-custom shadow border-0 mb-3">
    <div
      class="card-header text-white text-center rounded-only-top p-3"
      :class="{'refactor': isRefactor}">
      <small class="badge bg-warning text-uppercase me-1"
          v-if="$props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.intraoral_scanner_id != null">
        DIGITAL
      </small>
      <strong class="text-uppercase">
        <template v-if="$store.state.mainRole.team.name !== departments.PROGETTAZIONE && $props?.testWorkQueuePhaseAssigned?.test_work_queue?.prescription_test?.device">
          <span class="slot-not-found float-end position-relative cp" @click="notFound()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="'white'" class="bi bi-inbox" viewBox="0 0 16 16">
                <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
            </svg>
            <span class="question-icon position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :fill="'white'" class="bi bi-question" viewBox="0 0 16 16">
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
            </svg>
            </span>
          </span>
        </template>
      </strong>
      <strong>{{ formatDeliveryDate($props.testWorkQueuePhaseAssigned.test_work_queue) }}</strong>
    </div>

    <div class="card-body d-flex flex-column justify-content-between">
      <template v-if="$props.testWorkQueuePhaseAssigned">

        <div class="d-flex align-items-center">

          <template v-if="useBarcodeReader">
            <img class="me-2" src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="50" alt="scanner-barcode">
          </template>
          <template v-else>
            <img class="scanner-barcode me-2" @click="pastePrescriptionNumber()"
                src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="50" alt="scanner-barcode">
          </template>

          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="text-no wrap me-2">{{ isRefactor ? 'Rifacimento' : 'Prescrizione'}}</span>
              <span class="fw-bold fs-5 text-nowrap"> {{`${$props?.testWorkQueuePhaseAssigned?.test_work_queue?.prescription_test?.device?.name ?? ""} -`}}</span>
              <span class="fw-bold fs-5">
                {{ $props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.number_text }}
              </span>
            </div>
            <small style="max-width: 12rem; font-weight: bold;"><span class="text-uppercase"> {{ $props.testWorkQueuePhaseAssigned?.test_work_queue.prescription_test?.name }} </span> - {{ $props.testWorkQueuePhaseAssigned?.test_work_queue.prescription_test?.prescription?.prescription_rows[0]?.name }}</small>
            <small>{{ $props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.notes ?? 'Nessuna nota presente' }}</small>
          </div>
        </div>

        <div class="d-flex align-items-center border-top pt-2">
          <img class="me-3 cp" src="@/assets/icons/switch-user.svg" height="60" alt="customer" @click="technicianSuspend()">
          <div class="d-flex flex-column">
            <small v-if="isRefactor"
              class="text-uppercase fw-bold">Rifacimento</small>
            <div>
              <small class="text-uppercase fw-bold">nome: </small>
              {{ $props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.customer.first_name }}
            </div>
            <div>
              <small class="text-uppercase fw-bold">cognome: </small>
              {{ $props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.customer.last_name }}
            </div>
          </div>
        </div>

      </template>
    </div>

  </div>
</template>

<script>
import { computed, inject } from "vue";
import moment from "moment";
import { isRefactorPrescription } from "@/use/utilities/isRefactorPrescription";

export default {
  name: "BoxInformation",
  props: ['testWorkQueuePhaseAssigned'],
  emits: [
    'paste',
    'not-found',
    'technician-suspend',
  ],
  setup(props, {emit}) {
    const { departments } = inject("constants");
    const useBarcodeReader = computed(() => {
      let departmentSlug = props.testWorkQueuePhaseAssigned?.internal_phase?.department?.slug;
      return departmentSlug === departments.ISO_FISSA || departmentSlug === departments.ISO_MOBILE;
    });

    const pastePrescriptionNumber = () => {
      emit('paste', props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.number_text);
    }

    const notFound = () => {
      emit('not-found', props.testWorkQueuePhaseAssigned);
    }
    const technicianSuspend = () => {
      emit('technician-suspend', props.testWorkQueuePhaseAssigned);
    }
    const formatDeliveryDate = (testWorkQueue) => {
      return moment(testWorkQueue.delivery_date).format("DD/MM/YYYY HH:mm:ss");
    };

    const isRefactor = computed(() => {
      return isRefactorPrescription(props.testWorkQueuePhaseAssigned.test_work_queue.prescription_test.prescription.number_text);
    })

    return {
      isRefactorPrescription,
      pastePrescriptionNumber,
      useBarcodeReader,
      formatDeliveryDate,
      departments,
      notFound,
      isRefactor,
      technicianSuspend
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "@/scss/abstracts/_variables.scss";
  .card-header {
    background-color: $orange;
  }
  .card-header.refactor {
    background-color: #301934;
  }
  .scanner-barcode:hover {
    cursor: pointer;
    left: 20px;
    top: -10px;
  }
</style>
