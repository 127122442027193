import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(deviceId, customerId) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let device = [];

  await axios
    .get(`/devices/${deviceId}`, {
      params: {
        action: 'with_checkin_customer_validation',
        customer_id: customerId
      }
    })
    .then(async (resp) => {
      device = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { device };
}
