import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import moment from "moment";
import { setupCalendar } from 'v-calendar';
import PusherPlugin from "./plugins/pusher";
import draggable from "vuedraggable";
import { pusherAuthorize } from "@/use/pusher-authorize";
import Toast from "vue-toastification";
import VueViewer from "v-viewer";
import { createI18n } from "vue-i18n/index";
import it from "./translations/it.json";
import constants from "./plugins/constants";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import BarcodeHandler from './utils/barcodeHandler';

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "viewerjs/dist/viewer.css";

// bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const i18n = new createI18n({
  locale: "it",
  messages: {
    it,
  },
  fallbackLocale: "it",
});

// Initialize the barcode handler
const barcodeHandler = new BarcodeHandler((barcode) => {
  document.dispatchEvent(new CustomEvent('barcode-scanned', { detail: { barcode } }));
});

// Add global keydown listener
document.addEventListener('keydown', (event) => {
  barcodeHandler.processKey(event);
});

const app = createApp(App);
app.config.devtools = true;

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "primolab.app", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app
  .use(store)
  .use(router)
  .use(moment)
  .use(draggable)
  .use(VueViewer)
  .use(Toast)
  .use(i18n)
  .use(constants)
  .use(PusherPlugin, {
    apiKey: process.env.VUE_APP_PUSHER_KEY,
    cluster: "eu",
    authorizer: pusherAuthorize,
  })
  .use(setupCalendar, {})
  .mount("#app");
