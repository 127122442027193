<template>

  <template v-if="selectedTestWorkQueuePhase.length > 0 || testWorkQueuePhases.length > 0 || countAssignTestWorkQueuePhaseAtStations > 0">
    <div class="d-flex justify-content-center align-items-center w-100 vh-100">
      <div class="d-flex w-100 p-4 h-100">
        <div class="d-flex w-25">
          <div class="d-flex w-100 flex-column align-items-center p-3 overflow-auto pb-0"
               :style="{ 'justify-content': stationNumberSelected !== '' ? 'unset' : 'space-between' }">

            <Brand :width="200" />
            <div class="m-3">
              <Toggle v-model="missingToggle" />
              <label class="ms-2 fw-bold">{{ missingToggle ? 'Disabilita' : 'Abilita' }} ricerca tag</label>
            </div>
            <button class="btn btn-violet rounded-custom text-uppercase d-flex align-items-center" style="padding: 2rem;"
              @click="openMissingModal()"
              :disabled="!searchableTestWorkQueuePhases.length"
              >
              <span>Cerca tag</span>
              <span class="ms-4 slot-not-found float-end position-relative cp" @click="notFound()"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="'white'" class="bi bi-inbox" viewBox="0 0 16 16"><path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/></svg>
                <span class="question-icon position-absolute"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :fill="'white'" class="bi bi-question" viewBox="0 0 16 16"><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/></svg></span>
              </span>
            </button>

            <!-- All stations -->
            <template v-for="(station, index) in stations" :key="index">
              <h2 v-if="index === 0" class="text-uppercase text-violet text-center mb-4">seleziona la postazione</h2>
              <Station :style="{ cursor: selectedTestWorkQueuePhase.length > 0 && ! missingFlow  ? 'pointer' : 'not-allowed' }"
               v-if="stationNumberSelected === index + 1 || ! showActions"
               :slots="station.test_work_queue_phases"
               :index="index"
               :totStations="stations.length"
               @selected="showConfirm"
               @click="readTwqpSelected(station.id)"
              />
            </template>
            <!-- Confirm buttons -->
            <div v-if="showActions" class="d-flex align-items-center justify-content-center flex-column">
              <button type="button" class="btn btn-lg btn-violet text-uppercase mt-4" @click="assign()">
                conferma
              </button>
              <button type="button" class="btn btn-lg btn-outline-violet text-uppercase mt-4" @click="cancel()">
                annulla
              </button>
            </div>

          </div>
        </div>

        <div class="d-flex w-75 table-container mb-2 overflow-hidden">
          <div class="d-flex card rounded-custom shadow border-0 w-100">
            <Table
              :title="`lavori in attesa - ${$props.label}`"
              :test_work_queue_phases="testWorkQueuePhases"
              :resetSelected="successfullyAssigned"
              :missingFlow="missingFlow"
              @twqp_selected="readSelection"
              @add_twqp="addTestWorkQueuePhase"
              @remove_twqp="removeTestWorkQueuePhaseByIndex"
              @emptySelected="successfullyAssigned = false"
            />
          </div>
        </div>

      </div>
    </div>
  </template>

  <template v-else>
    <Loading
      title="Check-in"
      :type="$props.label"
      :subtitle="'Libero, in attesa di lavori'"
    />
  </template>
  <Standard
    v-if="showMissingModal"
    :size="'xl'"
    :modal_id="'missing_modal'"
    :title="'Casella non trovata'"
    :subtitle="'Puoi ricercarla, o sospendere la casella selezionata'"
    :decline_label="null"
    :lightLayout="false"
    :options="{ keyboard: false, backdrop: 'static'}"
    style="z-index: 999991"
    @hidden="closeMissingModal">
    <div class="pt-4" style="min-height: 65vh;">
      <MissingWorksWizard
        :selectableData="searchableTestWorkQueuePhases"
        :open="showMissingModal"
        :verticalDesign="true"
      >
      </MissingWorksWizard>
    </div>
  </Standard>
</template>

<script>
import {
  onMounted,
  ref,
  getCurrentInstance,
  onUnmounted,
  computed,
  toRaw,
  watch
} from "vue";
import {useStore} from "vuex";

import Brand from "@/components/Brand.vue";
import Station from "@/components/plaster/Station.vue";
import Table from "@/components/plaster/table/Table.vue";
import Loading from "@/components/plaster/Loading";
import Standard from "@/components/Modal/Standard";
import MissingWorksWizard from "@/components/devices/MissingWorksWizard";
import Toggle from '@vueform/toggle';
import moment from "moment";
import { formatDate } from "@/use/utilities/time/formatDate";
import { hideModal as closeModal } from "@/use/modal/hide";
import { fetchByRole as fetchStationByRole } from "@/use/repositories/users/fetchByRole";
import { update as updateTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/update";
import { fetchBySlug } from "@/use/repositories/testWorkQueuePhase/fetchBySlug";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import { fetchById as showPrescriptionTest } from "@/use/repositories/prescriptionTests/fetchById";
import _ from "lodash";

export default {
  name: "Checkin",
  props: {
    label: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    }
  },
  components: {
    Brand,
    Station,
    Table,
    Loading,
    Standard,
    MissingWorksWizard,
    Toggle
},
  setup(props) {
    const missingFlow = ref(false)
    const missingToggle = ref(false);

    watch(() => missingToggle.value, () => {
      testWorkQueuePhases.value.push(...selectedTestWorkQueuePhase.value);
      selectedTestWorkQueuePhase.value = [];
      resetSeletedPhases();
      missingFlow.value = missingToggle.value
    });

    const showMissingModal = ref(false);
    const closeMissingModal = () => {
      closeModal('missing_modal');
      showMissingModal.value = false;
    }
    const openMissingModal = () => {
      showMissingModal.value = true;
    }
    const searchableTestWorkQueuePhases = computed(() => {
      let testWorkQueuePhases =  _.filter(selectedTestWorkQueuePhase.value, function (phase) {
        return phase.test_work_queue.prescription_test.device !== null;
      });

      testWorkQueuePhases = _.sortBy(testWorkQueuePhases, [(o) => {
        moment(o.test_work_queue.delivery_date).format("DD/MM/YYYY HH:mm:ss");
      }]);

      return _.map(testWorkQueuePhases, (value) => {
        return {
          id: value.id,
          prescription_test_id: value.test_work_queue.prescription_test.id,
          name: value.test_work_queue.prescription_test.prescription.number_text,
          first_name:
            value.test_work_queue.prescription_test.prescription.customer.first_name,
          last_name:
            value.test_work_queue.prescription_test.prescription.customer.last_name,
          delivery_date: formatDate(value.test_work_queue.delivery_date),
          device_id: value.test_work_queue.prescription_test?.device?.id,
          device_name: value.test_work_queue.prescription_test?.device?.name,
        };
      });
    });

    //
    const stationNumberSelected = ref(null);
    const testWorkQueuePhases = ref([]);
    const stations = ref([]);
    const showActions = ref(false);
    const userId = ref(null);
    const selectedTestWorkQueuePhase = ref([]);
    const successfullyAssigned = ref(false);
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;

    // Subscribe to channel.
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));

      channel.bind(eventName, async (event) => {
        console.log(event);
        // Check if phase is new.
        if (event.state.slug === "assigned") {
          setTimeout(async () => {
            const { prescriptionTest } = await showPrescriptionTest(event.test_work_queue.prescription_test.id, { action: "with_current_device" });
            event.test_work_queue.prescription_test.device = prescriptionTest?.device;
            testWorkQueuePhases.value.push(event);
          }, 20000);
        } else if (event.state.slug === "completed") {
          console.log("event received...", event);

          // Remove from selected ids.
          stations.value.forEach((station) => {
            station.test_work_queue_phases.forEach((item, index, object) => {
              if (item.id === event.id) {
                object.splice(index, 1);
              }
            });
          });

        }
      });
    };

    // Unsubscribe to channel.
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel(`presence-user.${store.state.user.id}`);
    });

    onMounted(() => {
      // Get test work queue phase.
      fetchBySlug(store.state.user.id, ["assigned"], store.state.mainRole.team.name).then((response) => {
        testWorkQueuePhases.value = response.user.test_work_queue_phases;

        // Added property delivery_date on first level on obj.
        testWorkQueuePhases.value.map((phase) => {
          phase.delivery_date = phase.test_work_queue.delivery_date;
        });

        orderByPriorityDate();
      });

      // Get stations.
      fetchStationByRole(props.role, "with_test_work_queue_phases").then((response) => {
        stations.value = response.users;
      });

      // Subscribe to channel.
      subscribe(`presence-user.${store.state.user.id}`, "assign_phase");
    });

    const orderByPriorityDate = () => {
      testWorkQueuePhases.value = _.sortBy(toRaw(testWorkQueuePhases.value), 'delivery_date', 'desc');
    }

    const countAssignTestWorkQueuePhaseAtStations = computed(() => {
      let count = 0;
      stations.value.forEach((station) => {
        count += station.test_work_queue_phases.length;
      });
      console.log("COUNT TWQP ASSIGNED: " + count);

      return count;
    });

    const resetSelectedStation = async () => {
      // Reset selected station.
      stationNumberSelected.value = null;
    };

    const readTwqpSelected = async (idStation) => {
      if (missingFlow.value) return;
      userId.value = idStation;
      if (selectedTestWorkQueuePhase.value.length > 0) {
        showActions.value = true;
      } else {
        await resetSelectedStation();
      }
    };

    const pushPhasesToStation = async (selectedStation) => {
      let stationObject = stations.value[selectedStation - 1];

      selectedTestWorkQueuePhase.value.forEach((phase) => {
        stationObject.test_work_queue_phases.push(phase);
      });

      // Clear test work queue selected.
      selectedTestWorkQueuePhase.value = [];
    };

    // Remove items when assigned to user.
    const resetSeletedPhases = async () => {
      successfullyAssigned.value = true;
    };

    const assign = async () => {
      selectedTestWorkQueuePhase.value.forEach((testWorkQueuePhase) => {
        console.log(`ID: ${testWorkQueuePhase.id} - NUMBER TEXT: ${testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text} - DATE: ${testWorkQueuePhase.test_work_queue.delivery_date}`);
      })
      // Order by delivery date.
      selectedTestWorkQueuePhase.value = _.orderBy(toRaw(selectedTestWorkQueuePhase.value), testWorkQueuePhase => testWorkQueuePhase.test_work_queue.delivery_date, ['delivery_date']);

      // Get only ids.
      let ids = selectedTestWorkQueuePhase.value.map((phase) => phase.id);
      console.log(ids);

      updateTestWorkQueuePhase({
        source_ids: ids,
        user_id: userId.value,
        action: "attach_user",
      }).then(async () => {
        updateTestWorkQueuePhase({
          source_ids: ids,
          state: "in-the-works",
          action: "update",
        }).then(async () => {
          // Update test work queue state.
          selectedTestWorkQueuePhase.value.forEach((phase) => {
            if (phase.parent_test_work_queue_phases.length === 0) {
              // Update test work queue "in-the-work" state.
              updateTestWorkQueue({
                id: phase.test_work_queue_id,
                state_slug: "in-the-works",
                action: "update_state"
              }).then((response) => {
                console.log(`UPDATE TWQ ID: ${response.testWorkQueue.id} STATE IN ${response.testWorkQueue.state.slug}`);
              });
            }
          });
        });

        showActions.value = false; // Hide confirm btn.
        await pushPhasesToStation(stationNumberSelected.value); // Add dots colors.
        await resetSelectedStation(); // Recalculate number of dots colors.
        await resetSeletedPhases(); // Empty selected phases.
      });
    };

    const showConfirm = async (value) => {
      stationNumberSelected.value = value;
    };

    const cancel = async () => {
      showActions.value = false;
      // Reset selection on station.
      await resetSelectedStation();
    };

    const readSelection = async (value) => {
      selectedTestWorkQueuePhase.value = value;
    };

    const addTestWorkQueuePhase = (twqp) => {
      testWorkQueuePhases.value.push(twqp);
    }

    const removeTestWorkQueuePhaseByIndex = (index) => {
      testWorkQueuePhases.value.splice(index, 1);
    }

    return {
      showConfirm,
      stationNumberSelected,
      cancel,
      testWorkQueuePhases,
      readSelection,
      readTwqpSelected,
      showActions,
      assign,
      stations,
      selectedTestWorkQueuePhase,
      countAssignTestWorkQueuePhaseAtStations,
      addTestWorkQueuePhase,
      removeTestWorkQueuePhaseByIndex,
      successfullyAssigned,
      showMissingModal,
      closeMissingModal,
      openMissingModal,
      searchableTestWorkQueuePhases,
      missingToggle,
      missingFlow
    };
  },
};
</script>
