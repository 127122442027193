<script>
import { computed, inject, nextTick, onMounted, reactive, ref, watch } from "vue";
import _head from "lodash/head";
import debounce from "lodash/debounce";
import moment from "moment";

import Logout from "@/components/Logout.vue";
import Brand from "@/components/Brand.vue";
import ModalPrescriptionDetails from "@/components/PrescriptionDetails";
import PrescriptionRejected from "@/components/PrescriptionRejected.vue";
import Empty from "@/components/Modal/Empty";
import SpecialActions from "@/components/SpecialActions";

import { hideModal } from "@/use/modal/hide";
import { update as updateState } from '@/use/repositories/testWorkQueue/update';
import { store as storeTestWorkQueue } from "@/use/repositories/testWorkQueue/store";
import { error } from "@/use/toast/error";
import { storeAsRejected } from "@/use/repositories/prescriptionTestRejected/storeAsRejected";
import { fetchRejectedReasons } from "@/use/repositories/prescriptionTestRejected/fetchRejectedReasons";
import { success } from "@/use/toast/success";
import { dateFormatter } from "@/use/utilities/time/dateFormatter";
import { goToConsulting } from "@/use/utilities/goToConsulting";
import { useI18n } from "vue-i18n";
import { fetchPrescriptionTest } from "@/use/repositories/prescriptions/fetchPrescriptionTest";
import { fetchById as validatePrescriptionTest } from "@/use/repositories/prescriptionTests/fetchById";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { print } from "@/use/repositories/devices/print";
import { update as updateDevice } from "@/use/repositories/devices/update";
import { show as validateDevice } from "@/use/repositories/devices/show";
import { index as fetchDevices } from "@/use/repositories/devices/index";
import { show as showCustomer } from "@/use/repositories/customer/show";

import BatteryAlert from "@/components/devices/BatteryAlert.vue";
import Toggle from '@vueform/toggle';

export default {
  name: "Home",
  methods: { dateFormatter },
  components: {
    PrescriptionRejected,
    Logout,
    Brand,
    ModalPrescriptionDetails,
    Empty,
    SpecialActions,
    BatteryAlert,
    Toggle
},
  setup() {
    const { devicesType, departments } = inject("constants");
    const i18n = useI18n();
    const barCodeInput = ref("");
    const deviceId = ref(null);
    const testWorkQueueIdSelected = ref();
    const testWorkQueues = ref([]);
    const prescriptionsInQueue = ref([]);
    const clearRejectedInfo = ref(false);
    const prescriptionRejectedReasonsId = ref([]);
    const prescriptionRejectedReasons = ref([]);
    const currentPrescription = ref();
    const checkinCompleted = ref(false);

    const displayRules = reactive({
      showStoreRejectedModal: false,
      showBatteryAlert: false,
      deviceInputDisabled: false,
      disableBarCodeInput: false,
      disabledCheckIn: false,
    })

    const currentCustomer = reactive({
      customer_id: null,
      delivery_clinic_id: null,
    });

    const disabledInput = computed(() => {
      return displayRules.disabledCheckIn && ! prescriptionRejectedReasonsId.value.length;
    });

    const initScanFlow = debounce(async () => {
      if (barCodeInput.value.trim() === "") return;

      if (barCodeInput.value.length === 4 && ! displayRules.deviceInputDisabled) validateDigitalLabel();
      else {
        if (checkinCompleted.value) {
          prescriptionsInQueue.value = [];
          checkinCompleted.value = false;
        }
        validatePrescription()
      }
    }, 1000);

    const validatePrescription = async () => {
      try {
        const response = await fetchPrescriptionTest(barCodeInput.value.trim(), "with_valid_prescription_test");
        currentPrescription.value = response.data;

        if (prescriptionRejectedReasonsId.value.length) {
          await rejectPrescription(currentPrescription.value);
          return;
        }

        if (isSameCustomer(currentPrescription.value)) {
          return error(i18n.t("You cannot associate prescription from different customers to the same tag"))
        }

        const validationResponse = await validatePrescriptionTest(
          currentPrescription.value.current_test.id,
          {
            action: "validate_checkin",
            is_digital: 0,
          }
        );

        await completeValidationAndShowCustomer(validationResponse);

        if (displayRules.deviceInputDisabled && ! displayRules.showCustomerExistFlow) {

          await completeCheckin();
        }
      } catch (err) {
        handleError(err);
      } finally {
        clearAndSetFocusInput();
      }
    };

    const parsePrescriptionData = (testWorkQueueData, prescriptionTest) => {
      const { prescription } = prescriptionTest;
      const { delivery_clinic: clinic, doctor, customer } = prescription;

      return {
        prescription, clinic, doctor, customer,
        prescription_test: prescriptionTest,
        priorityDate: testWorkQueueData.delivery_date,
        selectedFork: _head(testWorkQueueData.test_work_queue_phases),
        testWorkQueueId: testWorkQueueData.id
      };
    };

    const completeValidationAndShowCustomer = async (validationResponse) => {

      await approveWork(validationResponse.prescriptionTest);

      const { customer } = await showCustomer(_head(prescriptionsInQueue.value).customer.id, 'with_devices');

      if (customer.devices.length) setDeviceAndShowToUser(_head(customer.devices));
    }

    const approveWork = async (prescriptionTest) => {
      try {
        const response = await storeTestWorkQueue(prescriptionTest.id, "analog_check_in");
        const { testWorkQueue } = response;
        const testWorkQueueData = testWorkQueue.data;
        queuePrescriptionForCustomer(testWorkQueueData, prescriptionTest);
      } catch (err) {
        handleError(err);
      }
    };

    const queuePrescriptionForCustomer = (testWorkQueueData, prescriptionTest) => {
      const parsedData = parsePrescriptionData(testWorkQueueData, prescriptionTest);
      addToQueue(parsedData);
      currentCustomer.customer_id = prescriptionTest.prescription.customer.id;
      currentCustomer.delivery_clinic_id = prescriptionTest.prescription.delivery_clinic.id
    }

    const printLabel = async (queueIds) => {
      try {
        const response = await fetchAllTesWorkQueue({action: 'with_base_64_labels', ids: queueIds}, true);

        if (response.testWorkQueue.length) {
          for (const testWorkQueue of response.testWorkQueue) {
            await print({
              device_type_slug: devicesType.ANALOG_LABEL,
              department_slug: departments.LOGISTICS,
              data: testWorkQueue.raw_data,
            });
          }
        }
      } catch (err) {
        handleError(err);
      }
    };

    const handleError = async(err) => {
      const showError = (message) => {
        error(message);
      }
      if (Array.isArray(err)) {
        for (const message of err) {
          await handlePrescriptionError(message)
          showError(message)
        }
      } else {
        await handlePrescriptionError(err);
        showError(err)
      }
    };

    /*
      If there is already a prescription in the laboratory that is digital that does not have an associated tag
      and is in a allowed department:
      allow association and position display.
    */
    const handlePrescriptionError = async (message) => {
      if (currentPrescription.value?.current_test?.intraoral_scanner_id && message === "Prescription is already in this laboratory.") {

        const { prescriptionTest } = await validatePrescriptionTest(currentPrescription.value.current_test.id, { action: "with_current_department" });

        if (prescriptionTest.device?.id) return setTimeout(() => goToConsulting(currentPrescription.value.id, true), 2000);

        const notAllowedDepartments = [ departments.CAM, departments.FISSA, departments.MOBILE ];
        const { department } = _head(prescriptionTest.test_work_queue.test_work_queue_phases)?.internal_phase;

        if (department && ! notAllowedDepartments.includes(department.slug)) {
          queuePrescriptionForCustomer(prescriptionTest.test_work_queue, prescriptionTest);
        }

        currentPrescription.value = null;
      }
    }

    const setDeviceAndShowToUser = (device) => {
      deviceId.value = device.id;
      displayRules.showCustomerExistFlow = true;
    }

    const validateDigitalLabel = async () => {

      displayRules.showBatteryAlert = false;

      if (! prescriptionsInQueue.value.length) {
        return error(i18n.t("Scan or insert a prescription before!"));
      }

      try {
        const response = await fetchDevices("by_name", barCodeInput.value, "name_term");
        if (! response.data.length) return error(i18n.t("Tag not found, try again"));

        const id = _head(response.data).id;

        const { device } = await validateDevice(id, currentCustomer.customer_id);

        if (device.extra_data?.battery !== "ok") {
          return displayRules.showBatteryAlert = true;
        }

        deviceId.value = device?.id;

        if (deviceId.value) {
          await completeCheckin();
        }
      } catch (err) {
        handleError(err);
      }
    }

    const rejectAndComplete = async () => {
      deviceId.value = null;
      displayRules.showCustomerExistFlow = false;
      await completeCheckin();
    }

    const completeCheckin = async () => {

      if (! displayRules.deviceInputDisabled && deviceId.value) {
        await updateDevice(deviceId.value, {
          action: "attach_prescription_tests",
          prescription_test_ids: prescriptionsInQueue.value.map(({ prescription_test }) => prescription_test.id),
        });
      }

      await printLabel(prescriptionsInQueue.value.map(({ testWorkQueueId }) => testWorkQueueId));

      success(i18n.t("Barcodes print successfully!"));

      resetSession()
      checkinCompleted.value = true;
      clearAndSetFocusInput();
    }

    const resetSession = () => {
      for (let key in displayRules) {
        if (key !== "deviceInputDisabled") displayRules[key] = false;
      }
      for (let key in currentCustomer) currentCustomer[key] = null;
      deviceId.value = [];
    }

    const clearAndSetFocusInput = async () => {
      barCodeInput.value = "";
      await nextTick(() => {
        const input = document.getElementById("scanBarcode");
        if (input !== null) input.focus();
      });
    };

    const syncTesWorkQueues = async (id) => {
      let index = testWorkQueues.value.findIndex((twq) => twq.id === id);
      if (index !== -1) {
        testWorkQueues.value.splice(index, 1);
      }
      testWorkQueueIdSelected.value = null;
    };

    const formattedDate = (value) => {
      return moment(value).format("YYYY-MM-DD hh:mm");
    };

    const addToQueue = (prescriptionTest) => {
      prescriptionsInQueue.value.push(prescriptionTest);
    }

    const hideStoreRejectedModal = () => {
      hideModal("storeRejectedModal");
      displayRules.showStoreRejectedModal = false;
      prescriptionRejectedReasonsId.value = [];
    };

    const rejectPrescription = async (prescriptionTest, existing = null) => {
      const reasonSlug = prescriptionRejectedReasons.value.find((reason) => reason.value == _head(prescriptionRejectedReasonsId.value)).slug;
      storeAsRejected(prescriptionTest.id.toString(), 'sent', reasonSlug)
        .then(() => {
          clearRejectedInfo.value = true;
          displayRules.disabledCheckIn = false;
          prescriptionRejectedReasonsId.value = [];
          if (existing) {
            updateState({
              id: testWorkQueueIdSelected.value,
              state_slug: "non-complient",
              action: "update_state"
            });
            syncTesWorkQueues(testWorkQueueIdSelected.value);
            hideModal('storeRejectedModal');
          }
          error(i18n.t("Prescription rejected, communication sent via chat"));
        })
        .finally(() => {
          clearAndSetFocusInput();
        });
    };

    const setMotivationIdSelected = (motivationId) => {
      prescriptionRejectedReasonsId.value = [motivationId];
    }

    const isSameCustomer = (prescription) => {
      return (currentCustomer.customer_id !== null && currentCustomer.delivery_clinic_id !== null) &&
            (currentCustomer.customer_id !== prescription.customer_id && currentCustomer.delivery_clinic_id !== prescription.delivery_clinic_id)
    }

    onMounted(() => {
      fetchRejectedReasons().then((response) => {
        prescriptionRejectedReasons.value = response.rejectedReasons;
      });
      clearAndSetFocusInput();
    });

    watch(
      () => prescriptionRejectedReasonsId.value.length,
      (value) => {
        if (value > 0) clearAndSetFocusInput();
      }
    );

    watch(
      () => displayRules.disabledCheckIn,
      (value) => {
        if (!value) clearAndSetFocusInput();
      }
    );

    return {
      barCodeInput,
      formattedDate,
      initScanFlow,
      prescriptionRejectedReasonsId,
      disabledInput,
      clearRejectedInfo,
      rejectPrescription,
      prescriptionRejectedReasons,
      hideStoreRejectedModal,
      hideModal,
      setMotivationIdSelected,
      completeCheckin,
      prescriptionsInQueue,
      displayRules,
      deviceId,
      rejectAndComplete
    };
  },
};
</script>

<template>
  <div class="d-flex justify-content-between w-50">
    <Logout />
    <Brand :width="120" :height="120" />
  </div>
  <div>
    <div class="alert alert-primary d-flealign-items-center m-3 px-4 rounded-custom" role="alert">
      <li> Scansionare prima il barcode della prescrizione e successivamente il Tag.</li>
      <li> È possibile associare la prescrizione ad un Tag esistente se il paziente risulta presente in laboratorio.</li>
      <li> Solo in caso di mancata disponibilità di Tag, è possibile proseguire senza associazione.</li>
    </div>
  </div>

  <div class="container d-flex flex-wrap">
    <div class="col-sm-12 col-md-6 p-1"  :class="{ 'm-auto': ! prescriptionsInQueue.length }">
    <BatteryAlert v-if="displayRules.showBatteryAlert" class="m-auto mb-2 border-danger" />
      <div class=" card rounded-custom shadow border-0 p-4">
        <div class="card-body">
          <h3 class="mb-4 fw-normal text-center">Check-in</h3>
          <PrescriptionRejected
            v-if="! prescriptionsInQueue.length"
            :clear="clearRejectedInfo"
            :prescription-rejected-reasons="prescriptionRejectedReasons"
            @enabled="value => displayRules.disabledCheckIn = value"
            @rejected-reason-ids-selected="id => prescriptionRejectedReasonsId = id" />
          <div class="mb-3">

            <div class="input-group mb-3">
              <span class="input-group-text">
                <img src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="30" alt="scanner-barcode" />
              </span>
              <input type="text" id="scanBarcode" autocomplete="off" class="form-control"
                placeholder="Scansiona il codice a barre" v-model="barCodeInput" :disabled="disabledInput" @input="initScanFlow"/>
            </div>

            <div v-if="! displayRules.disabledCheckIn" class="mb-3">
              <Toggle v-model="displayRules.deviceInputDisabled" />
              <label class="ms-2 fw-bold"> Associazione Tag {{ displayRules.deviceInputDisabled ? 'non attiva' : 'attiva' }}</label>
            </div>
          </div>
          <div class="">
          </div>
          <template v-if="displayRules.showCustomerExistFlow" >
            <h5 class="card-header rounded-custom d-flex">
              <img class="me-3" src="@/assets/icons/danger.svg" width="35" />
              Il paziente {{ prescriptionsInQueue[0].customer.first_name}} {{ prescriptionsInQueue[0].customer.last_name}} è già presente in laboratorio.
            </h5>
            <div class="card-body">
              <p class="card-text">Associare questa prescrizione al Tag esistente?</p>
              <div class="d-flex justify-content-around">
                <button @click="completeCheckin" href="#" class="btn btn-violet">Conferma</button>
                <button @click="rejectAndComplete" href="#" class="btn btn btn-outline-violet">Rifiuta</button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- TODO: mantenere visibile l'informazione fino alla successiva scansione -->
    <div v-if="prescriptionsInQueue.length" class="col-sm-12 col-md-6">
      <ul class="list-group p-1 list-unstyled">
        <li v-for="prescription in prescriptionsInQueue" :key="prescription" >
          <ModalPrescriptionDetails :data="prescription"/>
        </li>
      </ul>
    </div>
  </div>

  <Empty v-if="displayRules.showStoreRejectedModal" :size="'md'" :modal_id="'storeRejectedModal'" @hidden="hideStoreRejectedModal">
    <SpecialActions style="height: 400px" :title="'Seleziona il motivo della tua richiesta'"
      :placeholderNameSelect="'Seleziona la motivazione'" :optionsSelect="prescriptionRejectedReasons" :notes="false"
      @selected="setMotivationIdSelected" @clear="clearRejectedInfo" @store="rejectPrescription(true)"
      @close="hideModal('storeRejectedModal');" />
  </Empty>
</template>

<style scoped>
#slot-details {
  position: absolute;
  width: 20%;
  left: 20%;
  top: 33%;
}

input[type="text"]:focus {
  border-color: rgb(225 210 242);
  box-shadow: 0 0 0 0.25rem rgb(225 210 242);
  outline: 0 none;
}

tr th {
  text-transform: uppercase;
}

.trash:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.overflow-card {
  max-height: 65vh;
  overflow-y: scroll;
}

.not-focused {
  opacity: 0.5;
}

/* PRESCRIPTIONS */
.prescription {
  border: 1px solid;
  padding: 3px 6px;
  margin-bottom: 6px;
  border-radius: 0.5rem;
}
</style>
