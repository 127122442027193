import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function update(deviceId, params) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let device = [];

  await axios
    .put(`/devices/${deviceId}`, params)
    .then(async (resp) => {
      device = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { device };
}
