<template>
  <div id="prescription-details" class="card rounded-custom shadow border-0 mb-1">
    <div class="card-body">
      <div class="d-flex justify-content-between my-2">
        <h4>{{ $props.data.prescription.number_text }}</h4>
        <LastDate
          :clinic-id="$props.data.clinic.id"
          :customer-id="$props.data.customer.id"
        />
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <div class="d-flex">
            <div class="d-flex flex-column me-3">
              <img src="@/assets/icons/logistic-checkin/calendar.svg" width="40" alt="calendar">
              <div class="d-flex h-100 justify-content-center align-items-center">
                <img src="@/assets/icons/logistic-checkin/vertical-dots.svg" width="10" height="80" alt="vertical-dots">
              </div>
            </div>
            <div class="d-flex flex-column">
              <strong>{{ moment($props.data.prescription_test?.date?.start_at).format("DD/MM/YYYY - HH.mm") }}</strong>
              <small><strong>{{ $props.data.prescription_test.name }}</strong></small>
              <small>{{ $props.data.prescription_test.notes ?? i18n.t('No notes present') }}</small>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img class="me-3" src="@/assets/icons/logistic-checkin/van.svg" width="40" alt="van">
            <strong>{{ moment($props.data.priorityDate).format("DD/MM/YYYY - HH.mm") }}</strong>
          </div>
        </div>
        <div class="small text-center m-2">
          <div class="col">
            <img src="@/assets/icons/logistic-checkin/dental-chair.svg" width="40" alt="dental-chair">
            <small class="d-block">{{ $props.data.clinic.name }}</small>
            <small>
              Tel.
              <a class="link-dark" :href="'tel:' + $props.data.clinic.phone">
                {{ $props.data.clinic.phone }}
              </a>
            </small>
          </div>
          <div class="col">
            <img src="@/assets/icons/logistic-checkin/customer.svg" width="40" alt="customer">
            <small class="d-block">{{ $props.data.customer.first_name }}</small>
            <small>{{ $props.data.customer.last_name }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="info-departments card-header w-100 rounded-only-bottom border">
      <div class="d-flex justify-content-between">
        <strong>Fase interna: {{ $props.data.selectedFork.internal_phase.name }}</strong>
        <strong>Area: {{ $props.data.selectedFork.internal_phase.department.name }}</strong>
      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment";
import { useI18n } from "vue-i18n";

import LastDate from "@/components/Checkin/LastDate.vue";

export default {
  name: "PrescriptionDetails",
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
  emits: [
    'focus'
  ],
  components: {
    LastDate,
  },
  setup() {
    const i18n = useI18n();

    return {
      moment,
      i18n
    };

  },
};
</script>
