<template>
  <div class="container py-2">
    <div class="mb-4">
      <div class="container-fluid py-2">
        <video :id="getVideoId" class="video-js vjs-default-skin" playsinline></video>
        <div class="d-flex justify-content-center bd-highlight bg-dark">
          <div
            v-if="!isRecording && isPauseRecording"
            @click="stopRecording()"
            class="p-2 bd-highlight cursor-pointer"
          >
            <img class="mx-2" src="@/assets/icons/media/check-circle.svg" width="30" alt="ok" />
          </div>
          <div
            v-if="!isRecording && isPauseRecording"
            @click="resumeRecording()"
            class="p-2 bd-highlight cursor-pointer"
          >
            <img class="mx-2" src="@/assets/icons/media/play.svg" width="30" alt="play" />
          </div>
          <div
            v-if="isRecording && !isPauseRecording"
            @click="pauseRecording()"
            class="p-2 bd-highlight cursor-pointer"
          >
            <img class="mx-2" src="@/assets/icons/media/stop.svg" width="30" alt="stop" />
          </div>
          <div
            v-if="!isRecording && isPauseRecording"
            @click="closeRecording()"
            class="p-2 bd-highlight cursor-pointer"
          >
            <img class="mx-2" src="@/assets/icons/media/close-circle.svg" width="30" alt="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";

import _head from "lodash/head";
import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import { error } from "@/use/toast/error";
import { useI18n } from "vue-i18n";

export default {
  props: {
    testWorkQueueIds: { type: Array, required: true },
    numberText: { type: Array, required: true },
  },
  computed: {
    getVideoId() {
      return "myVideo__" + _head(this.testWorkQueueIds);
    },
    getVideoName() {
      return _head(this.numberText) + "." + this.fileExtension;
    },
    qualityNetwork() {
      return navigator.connection.effectiveType;
    }
  },
  emits: [
    "setMedia",
    "closeModal",
    "resetSelection"
  ],
  data() {
    return {
      i18n: useI18n(),
      player: "",
      isRecording: false,
      isPauseRecording: false,
      isStopRecording: false,
      fileExtension: "",
      resolutionWidth: 1920,
      resolutionHeight: 1080,
      options: {
        language: "it",
        controls: false,
        autoplay: false,
        fluid: true,
        loop: false,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
        },
        plugins: {
          // configure videojs-record plugin
          record: {
            audio: false,
            debug: true,
            video: {
              // video media constraints: set resolution of camera.
              width: this.resolutionWidth,
              height: this.resolutionHeight
            },
            frameWidth: this.resolutionWidth,
            frameHeight: this.resolutionHeight,
            maxLength: 300, // set to 5 minutes
          },
        },
      },
    };
  },
  methods: {
    navigatorOnline() {
      return navigator.onLine;
    },
    scalingResolution(effectiveType) {
      switch (effectiveType) {
        case 'slow-2g':
        case '2g':
          this.resolutionWidth = 854;
          this.resolutionHeight = 480;
          break;
        case '3g':
          this.resolutionWidth = 1280;
          this.resolutionHeight = 720;
          break;
        case '4g':
          this.resolutionWidth = 1920;
          this.resolutionHeight = 1080;
          break;
      }
    },
    startRecording() {
      this.player.record().getDevice();
      this.isRecording = true;
    },
    pauseRecording() {
      this.player.record().pause();
      this.isPauseRecording = true;
      this.isRecording = false;
    },
    resumeRecording() {
      this.player.record().resume();
      this.isPauseRecording = false;
      this.isRecording = true;
    },
    stopRecording() {
      this.player.record().stop();
      this.isStopRecording = true;
      this.isRecording = false;
    },
    closeRecording() {
      this.player.record().destroy();
      this.isStopRecording = false;
      this.isPauseRecording = false;
      this.isRecording = false;
      this.$emit("resetSelection");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async attachVideo() {
      // Define axios config.
      const axios = axiosInstance().primoLab;
      try {
        const firstId = _head(this.testWorkQueueIds);
        const response = await axios.post("/test-work-queues/attach-video", {
          test_work_queue_id: firstId,
          filename: this.getVideoName,
        });
        const ids = this.testWorkQueueIds.filter(id => id !== firstId);

        if (ids.length) {
          for (const id of ids) {
            await updateTestWorkQueue({
              id: id,
              state_slug: "bagged",
              action: "update_state"
            });
          }
        }
        console.log("Video uploaded successfully");
        // Emit data to upload.
        this.$emit("setMedia", response.data.data);
        this.player.record().stopDevice();
        this.player.record().destroy();
        this.closeModal();

      } catch (error) {
        console.error(error);
      }
    },
    async uploadVideo(videoBlob) {
      if (!videoBlob) return;
      // Define axios config.
      const axios = axiosInstance().primoLab;

      try {
        let formData = new FormData();
        formData.set("filename", this.getVideoName);

        await axios
          .post("/test-work-queues/video-signed-url", formData)
          .then(async (response) => {
            // Get signed url.
            let inputs = response.data.data.inputs;
            let attributes = response.data.data.attributes;

            formData = new FormData();
            Object.keys(inputs).forEach((key) => {
              formData.append(key, inputs[key]);
            });

            const blob = new Blob([videoBlob], {
              type: 'video/webm'
            });

            formData.append("file", blob, this.getVideoName);

            // Upload video to S3
            const s3Response = await fetch(attributes.action, {
              method: "POST",
              body: formData,
              mode: "cors",
            });

            if (s3Response.status === 204) {
              // Attach video to test work queue.
              await this.attachVideo();
            } else {
              console.log("Video upload failed");
              this.player.record().stopDevice();
              this.closeModal();
            }
          });
      } catch (err) {
        this.player.record().stopDevice();
        this.closeModal();
        console.log("Failed to upload");
        console.log(err);
      }
    },
  },
  mounted() {
    if (this.navigatorOnline() === false) {
      return error(this.i18n.t('An error has occurred. Contact the technical service!'));
    }

    this.scalingResolution(this.qualityNetwork);

    /* eslint-disable no-console */
    this.player = videojs(this.getVideoId, this.options, () => {
      // print version information at startup
      var msg =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(msg);
    });

    // Launch record
    this.startRecording();

    // device is ready
    this.player.on("deviceReady", async () => {
      console.log("device is ready!");

      await this.player.record().start();
    });

    // user clicked the record button and started recording
    this.player.on("startRecord", () => {
      console.log("started recording!");
    });

    // user completed recording and stream is available
    this.player.on("finishRecord", () => {
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      console.log("finished recording: ", this.player.recordedData);

      this.fileExtension = this.player.recordedData.name.split(".").pop();

      // Upload video to S3
      this.uploadVideo(this.player.recordedData);
    });

    // error handling
    this.player.on("error", (element, error) => {
      console.warn(error);
    });

    this.player.on("deviceError", () => {
      console.error("device error:", this.player.deviceErrorCode);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
