<template>

  <template v-if="slots.length === 0">
    <Loading
      :title="$store.state.user.first_name"
      :type="stationType"
      subtitle="In attesa di lavori"
    />
  </template>

  <template v-else>
    <div class="d-flex flex-column p-4 vw-100 vh-100">
      <div class="d-flex flex-column">

        <!-- Header -->
        <div class="d-flex justify-content-start align-items-center">

          <Counter
            class="ps-3"
            :count-slots="count"
            :station-name="$store.state.user.first_name + ' - ' + stationType"
          />

          <div class="d-flex mt-4 justify-content-center position-absolute top-0 start-50 translate-middle-x">
            <Brand :width="150" />
          </div>

        </div>

        <div class="d-flex ps-3">
          <button class="btn btn-violet mb-4 text-uppercase" @click="sortItems()">
            Riordina in base all'etichetta
          </button>
        </div>

      </div>

      <div class="d-flex flex-column h-100 mb-5">

        <!-- Cards -->
        <div class="d-flex flex-nowrap overflow-auto align-items-center">
          <Drag
            :elements="slots"
            :ids-twqp-selected="idsTestWorkQueueSelected"
            :show-only-selected="showOnlySelected"
            @twqp_selected="syncTestWorkQueueSelected"
            @showOnlySelected="resetShowOnlySelected"
          />
        </div>

      </div>

      <!-- Buttons -->
      <div class="d-flex flex-column justify-content-center align-items-center position-absolute bottom-0 start-50 translate-middle-x mb-4">
        <div v-if="! confirm" class="d-flex">
          <button type="button" class="btn btn-lg btn-outline-violet text-uppercase mt-4 bg-white me-2"
                  v-if="idsTestWorkQueueSelected.length > 0" @click="previewSelected">
            {{ getLabel(idsTestWorkQueueSelected.length) }}
          </button>
          <button type="button" class="btn btn-lg btn-violet text-uppercase mt-4" @click="selectAll">
            concludi tutti i lavori
          </button>
        </div>
        <div v-if="confirm && idsTestWorkQueueSelected.length > 0" class="d-flex">
          <button type="button" class="btn btn-lg btn-violet text-uppercase mt-4 me-2" @click="save">
            conferma
          </button>
          <button type="button" class="btn btn-lg btn-outline-violet text-uppercase mt-4 bg-white" @click="cancel()">
            annulla
          </button>
        </div>
      </div>

    </div>
  </template>

</template>

<script>
import {onMounted, ref, getCurrentInstance, onUnmounted, computed} from "vue";
import { useStore } from "vuex";

import { fetchBySlug as fetchSlotsStation } from "@/use/repositories/testWorkQueuePhase/fetchBySlug";
import { update as updateTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/update";
import { sortByPriorityDate } from "@/use/repositories/testWorkQueuePhase/sortByPriorityDate";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';

import Counter from "@/components/plaster/Counter";
import Loading from "@/components/plaster/Loading";
import Brand from "@/components/Brand";
import Drag from "@/components/Drag";
import { useToast } from "vue-toastification";

export default {
  name: "InTheWorksJobs",
  components: {
    Counter,
    Loading,
    Brand,
    Drag,
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    const slots = ref([]);
    const idsTestWorkQueueSelected = ref([]);
    const confirm = ref(false);
    const selected = ref(false);
    const count = computed(() => {
      return slots.value.length;
    });


    const selectedPhases = computed(() => {
      let data = [];
      const filteredData = slots.value.filter(item => idsTestWorkQueueSelected.value.includes(item.id));

      if (filteredData.length) {
        data = filteredData.sort((a, b) => {
          const indexA = idsTestWorkQueueSelected.value.indexOf(a.id);
          const indexB = idsTestWorkQueueSelected.value.indexOf(b.id);

          return indexA - indexB;
        });
      }

      return data;
    });

    const showOnlySelected = ref(false);
    // Pusher init.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const stationType = computed(() => {
      let type = "";
      if (store.state.user.roles.includes("postazione-colatura-gesso")) {
        type = "colatura"
      } else if (store.state.user.roles.includes("postazione-finitura-gesso")) {
        type = "rifinitura"
      }

      return type.toUpperCase();
    });

    // Unsubscribe to channel.
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    // Subscribe to channel.
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () =>
        console.log("subscription succeeded")
      );

      channel.bind(eventName, (event) => {
        if (event.state.slug === "assigned") {
          console.log("event received...", event);

          slots.value.push(event);
          resetOrderSlotsSavedOnVuex();

          // Order slots by priority if the order was customized.
          if (store.state.orderSlots.length === 0) {
            sortItems();
          }

          // Show message
          toast.info(`E' stata aggiunta una nuova prescrizione ${event.test_work_queue.prescription_test.prescription.number_text}... L'ordinamento è stato ripristinato per priorità!`, {
            position: "top-right",
            timeout: 9000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

        }
      });
    };

    onMounted(() => {
      fetchSlotsByStation();
      // Subscribe to channel.
      subscribe(`presence-user.${store.state.user.id}`, "assign_phase");
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel(`presence-user.${store.state.user.id}`);
    });

    const resetOrderSlotsSavedOnVuex = () => {
      store.dispatch("setOrderSlots", []);
    }

    const sortItems = () => {
      sortByPriorityDate(slots.value);
      store.dispatch("setOrderSlots", []);
    };

    const fetchSlotsByStation = async () => {
      await fetchSlotsStation(store.state.user.id, ["in-the-works"], store.state.mainRole.team.name).then(async (response) => {
        slots.value = response.user.test_work_queue_phases;

        if (store.state.orderSlots.length === 0) {
          await sortItems();
        } else {
          // Order array from vuex.
          slots.value.sort(function (a, b) {
            return store.state.orderSlots.indexOf(a.id) - store.state.orderSlots.indexOf(b.id);
          });
        }

      });
    };

    const syncTestWorkQueueSelected = (value) => {
      let index = idsTestWorkQueueSelected.value.indexOf(value);

      if (index === -1) {
        idsTestWorkQueueSelected.value.push(value);
      } else {
        idsTestWorkQueueSelected.value.splice(index, 1);
      }

      if (idsTestWorkQueueSelected.value.length === 0) {
        confirm.value = false;
      }
    };

    const save = async () => {
      await updateTestWorkQueuePhase({
        source_ids: idsTestWorkQueueSelected.value,
        state: "completed",
        action: "update",
      }).then(() => {
        // Test work queue ready to complete
        const queuesToBeCompleted = selectedPhases.value.filter(item => item.children_test_work_queue_phases.length === 0);

        queuesToBeCompleted.forEach((element) => {
          updateTestWorkQueue({
            id: element.test_work_queue_id,
            state_slug: "completed",
            action: "update_state"
          });
        });


        cancel(true);
      });
    };

    const selectAll = async () => {
      confirm.value = true;
      selected.value = true;
      for (const item of slots.value) {
        if (idsTestWorkQueueSelected.value.includes(item.id) === false) {
          idsTestWorkQueueSelected.value.push(item.id);
        }
      }
    };

    const cancel = (forceRemove = false) => {
      if (forceRemove) {
        // Remove all selected
        idsTestWorkQueueSelected.value.forEach((selectedItem) => {
          let index = slots.value.findIndex((phase) => phase.id === selectedItem);
          slots.value.splice(index, 1);
        });
      }

      showOnlySelected.value = false;
      confirm.value = false;
      idsTestWorkQueueSelected.value = [];
    };

    const getLabel = (quantity) => {
      return `concludi ${quantity === 1 ? 'il lavoro' : 'i lavori'} selezionat${quantity === 1 ? 'o' : 'i'}`;
    }

    const previewSelected = async () => {
      showOnlySelected.value = true;
      confirm.value = true;
    };

    const resetShowOnlySelected = (value) => {
      showOnlySelected.value = value;
    };

    return {
      slots,
      syncTestWorkQueueSelected,
      idsTestWorkQueueSelected,
      save,
      confirm,
      selectAll,
      selected,
      cancel,
      previewSelected,
      count,
      sortItems,
      showOnlySelected,
      resetShowOnlySelected,
      stationType,
      getLabel,
    };
  },
};
</script>
