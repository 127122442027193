<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { countByDepartment } from "@/use/repositories/devices/countByDepartment.js";
import CardCounter from "@/components/devices/CardCounter.vue";

export default {
  name: "Counters",
  setup() {
    const store = useStore();
    const loadig = ref();
    const counters = ref();
    const syncCounters = () => {
      loadig.value = true;
      countByDepartment(store.state.mainRole.team.name).then((response) => {
        counters.value = response?.data?.meta;
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        loadig.value = false;
      })
    }

    onMounted(() => {
      syncCounters();
    })

    return { syncCounters, loadig, counters};
  },
  components: { CardCounter }
}
</script>

<template>
  <div class="d-flex w-50 m-0 ps-5" style="height: 8rem;">
    <CardCounter :label="'Tot. Tag'" :loading="loadig" :value="counters?.attached_devices + counters?.empty_devices" />
    <CardCounter :label="'Tot. Tag assegnati'" :loading="loadig" :value="counters?.attached_devices" />
    <CardCounter :label="'Tot. Tag non assegnati'" :loading="loadig" :value="counters?.empty_devices" />
    <div class="d-flex justify-content-center align-items-end m-2" style="width: 4rem;">
      <img class="p-1 rounded-circle shadow cp" style="background: #A58ABA" src="@/assets/icons/sync.svg" width="40" height="40" alt="sync" @click="syncCounters()">
    </div>
  </div>
</template>

