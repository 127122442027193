import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAllRejected() {
  const axios = axiosInstance().primoLab;
  let prescriptionsRejected = [];

  await axios
    .get(`/prescription-rejections`)
    .then(async (response) => {
      prescriptionsRejected = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionsRejected };
}
