import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function show(customerId, action) {
  const axios = axiosInstance().primoLab;
  let customer = [];

  await axios
    .get(`/customers/${customerId}`, {
      params: { action: action }
    })
    .then(async (response) => {
      customer = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { customer };
}
