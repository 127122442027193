export function geolocalize(x, y) {
  const polygons = {
    "Area Preparazione": [
      [25.3, 10.5],
      [25.3, 2.7],
      [30.4, 2.7],
      [30.4, 10.5],
      [25.3, 10.5],
    ],
    "Area CAD": [
      [8.9, 29.9],
      [9.0, 20.4],
      [14.4, 20.4],
      [14.4, 8.0],
      [16.0, 8.0],
      [16.0, 11.2],
      [20.9, 11.2],
      [20.9, 10.5],
      [34.2, 10.5],
      [34.2, 26.0],
      [21.0, 26.0],
      [21.0, 29.9],
      [8.9, 29.9],
    ],
    "Finitura Mobile": [
      [1.0, 10.8],
      [1.0, 1.7],
      [14.4, 1.7],
      [14.4, 10.8],
      [1.0, 10.8],
    ],
    "Area Gessi": [
      [16.0, 11.2],
      [16.0, 2.8],
      [16.0, 2.7],
      [25.3, 2.7],
      [25.3, 10.5],
      [20.9, 10.5],
      [20.9, 11.2],
      [16.0, 11.2],
    ],
    "Finitura Fissa": [
      [1.0, 20.4],
      [1.0, 10.8],
      [14.4, 10.8],
      [14.4, 20.4],
      [1.0, 20.4],
    ],
    "Area CAM": [
      [29.9, 26.0],
      [34.2, 26.0],
      [34.2, 19.0],
      [40.0, 19.0],
      [40.0, 37.8],
      [29.9, 37.8],
      [29.9, 26.0],
    ],
    Forni: [
      [15.5, 43.0],
      [15.5, 37.8],
      [40.0, 37.8],
      [40.0, 43.0],
      [15.5, 43.0],
    ],
    "Area Logistica": [
      [5.8, 1.7],
      [5.8, -2.3],
      [30.3, -2.3],
      [30.3, 2.7],
      [16.0, 2.7],
      [16.0, 8.0],
      [14.4, 8.0],
      [14.4, 1.7],
      [5.8, 1.7],
    ],
  };

  function isPointInPolygon(point, polygon) {
    let inside = false;
    let [px, py] = point;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let [xi, yi] = polygon[i];
      let [xj, yj] = polygon[j];
      let intersect = yi > py !== yj > py && px < ((xj - xi) * (py - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  for (let area in polygons) {
    if (isPointInPolygon([x, y], polygons[area])) {
      return area;
    }
  }

  return "Outside Range";
}
