/**
 * Composable to validate input as device or number_text.
 * @returns { function }
 */

export function useBarCodeValidator() {
  /**
   * @param { string } textInput - The input to validate
   * @returns { string } - "device", "number_text", or "invalid"
   */
  const validateInput = (textInput) => {
    const trimmedInput = textInput.trim();

    if (trimmedInput.length === 0) return "invalid";

    const isDevice = /^\d{4}$/.test(trimmedInput);

    if (isDevice) return "device";
    
    return "number_text";
  };

  return { validateInput };
}
