<template>
  <button @click="goBack()" class="btn rounded-btn-custom shadow bg-violet text-white">
    <div class="d-flex align-items-center">
      <img src="@/assets/icons/arrow-left.svg" width="35" alt="arrow-left">
      <span class="fw-bold text-uppercase">{{ $props.label }}</span>
    </div>
  </button>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "ButtonBack",
  props: {
    label: {
      type: String,
      require: true,
    }
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      console.log(window.history);
      return router.go(-1);
    }
    return { goBack }
  }
}
</script>
