<template>

  <template v-if="Object.keys(tests).length > 0">

    <section class="d-flex h-100">

      <aside class="w-25 overflow-auto h-100 border-2 border-end h-100 p-3">
        <ul class="list-unstyled">
          <template v-for="(test, index) in tests" :key="index">
            <li class="test d-flex py-3" @click="selected(test)" :class="[{ 'opacity': ! test.selected, 'link': ! isSkipped(test.state_slug) }]">
              <span class="me-2 circle text-center rounded-circle text-white bg-dark-violet">{{ index + 1 }}</span>
              <span class="w-75 text-violet-dark">{{ test.name }} <em v-html="isSkipped(test.state_slug)"></em></span>
            </li>
          </template>
        </ul>
      </aside>

      <main class="w-75 h-100 m-4 overflow-auto">
        <History
          :prescription-test-id="prescriptionTestId"
          :item-to-show-slider="2"
          :item-to-show-when-resize="1"
        />
      </main>

    </section>

  </template>

  <template v-else>
    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="spinner-border text-violet" role="status"></div>
    </div>
  </template>

</template>

<script>
import {onMounted, ref} from 'vue';

import History from "@/components/Consulting/History";

import {fetchById as fetchPrescriptionById} from '@/use/repositories/prescriptions/fetchById';

export default {
  name: "HistoryList",
  props: {
    prescription: {
      type: Object,
      required: true,
    }
  },
  components: {
    History,
  },
  setup(props) {
    const tests = ref([]);
    const prescriptionTestId = ref(props.prescription.current_test_id);

    onMounted(() => {
      fetchPrescriptionById(props.prescription.id, 'with_prescription_tests:with_trashed').then((response) => {
        getTests(response.prescription.prescription_tests);
      });
    });

    const getTests = (prescriptionTests) => {
      prescriptionTests.forEach((prescriptionTest) => {
          return tests.value.push({
            id: prescriptionTest.id,
            state_slug: prescriptionTest.test_work_queue.state.slug,
            name: prescriptionTest.name,
            selected: prescriptionTest.id === props.prescription.current_test_id,
          });
      });
    };

    const isSkipped = (stateSlug) => {
      if (stateSlug === 'saltata') {
        return `<em>- Prova ${stateSlug}</em>`;
      }
    }

    const selected = (test) => {
      if (test.state_slug === 'saltata') return;
      let atLeastOneSelected = tests.value.some((test) => test.selected);

      if (atLeastOneSelected) {
        let testAlreadySelected = tests.value.find((test) => test.selected );

        if (testAlreadySelected.id === test.id) {
          console.log("ALREADY SELECTED");
          return;

        } else {
          tests.value.forEach((test) => test.selected = false);
        }
      }

      test.selected = true;
      prescriptionTestId.value = test.id;
    }


    return {
      tests,
      isSkipped,
      selected,
      prescriptionTestId,
    }
  }
};
</script>

<style scoped>
.circle {
  width: 25px;
  height: 25px;
}
.opacity {
  opacity: 0.4;
}
.link:hover {
  cursor: pointer;
}
</style>
