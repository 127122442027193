<template>
  <div class="day d-flex bg-white flex-column p-3 overflow-auto">

    <div class="d-flex align-items-center">

      <template v-if="afterTodayFilterCountAssetsByState($props.data, 'pending') > 0">
          <div class="checkbox d-flex" @click="massiveSelection($props.data)">
            <img v-if="! isDayChecked" src="@/assets/icons/checkbox.svg" height="25" alt="checkbox"/>
            <img v-else src="@/assets/icons/checkbox-checked.svg" height="25" alt="checkbox-checked"/>
          </div>
      </template>

      <h5 class="text-violet ms-2 my-2 text-uppercase">
        {{
          isTodayOrAfterToday(getPriorityDate($props.data.works))
            ? getPriorityDate($props.data.works).format("DD/MM/YYYY")
            : (afterTodayFilterCountAssetsByState($props.data, 'pending') > 0 ? 'Seleziona tutto' : '')
        }}
      </h5>

    </div>

    <!-- Works -->
    <template v-for="(el, index) in Object.values($props.data.works)" :key="index">
        <div class="d-flex p-2 flex-column">
          <template v-if="isTodayOrAfterToday(el.delivery_date) === false">
            <h5 class="d-flex text-violet mx-2 ms-0 ">
              {{ el.delivery_date.format("DD/MM/YYYY") }}
            </h5>
          </template>
          <div class="d-flex">
            <!-- Stl files -->
            <div class="mx-2 w-100" v-if="el.length > 0">
              <template v-for="(el, index) in el" :key="index">
                <template v-if="isTodayOrAfterToday(el.delivery_date) === false">
                  <h5 class="d-flex text-violet mx-2 ms-0" v-if="index === 0 || el.assets.lenght">
                    {{ el.delivery_date.format("DD/MM/YYYY") }}
                  </h5>
                </template>
                <template v-for="(file, index) in filterAssetsByState(el.assets, 'pending')" :key="index">
                  <div class="d-flex align-items-center">
                    <div class="checkbox my-1 me-2" @click="selectedCurrent(file, $props.data)">
                      <img v-if="! file.checked" src="@/assets/icons/checkbox.svg" height="20" alt="checkbox"/>
                      <img v-else src="@/assets/icons/checkbox-checked.svg" height="20" alt="checkbox-checked"/>
                    </div>

                    <!-- Models render -->
                    <template v-if="file.slug !== assetsType.MILLING">
                      <img class="me-2"
                           :src="require(`@/assets/icons/model/${getFolderNameIconModelBySlug(file.slug)}/${getFileNameIconModelBySlug(file.slug)}-sel.svg`)" height="20" alt="model"/>
                    </template>

                    <!-- Bridge render -->
                    <img v-if="file.bridge" class="me-2" src="@/assets/icons/project/bridge/bridge-sel.svg" height="20" alt="checkbox" />

                    <small
                      :data-id="file.id"
                      :title="getFileName(file.media)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="getFileName(file.media)"
                      class="file text-decoration-underline text-truncate"
                      @click="modalScene(file)">
                      {{ `${el.prescription_test.device?.name ? '' : 'NOTAG_'}`}}
                      {{ getFileName(file.media) }} {{ file.id }}
                    </small>

                    <template v-if="file.is_digital">
                      <div class="badge bg-warning text-uppercase me-1">digital</div>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </template>

  </div>

  <Modal v-if="showModal"
         :data="scene"
         @hidden="closeModal">
    <button type="button" class="btn btn-violet text-uppercase mt-4" @click="showFeedback()" :disabled="true">
      <img class="dislike" src="@/assets/icons/dislike-white.svg" width="25" alt="dislike" />
    </button>
  </Modal>

  <Empty v-if="showModalFeedback"
    size="md"
    :modal_id="scene.id"
    @hidden="destroyModalFeedback">

    <pre>
      {{ scene }}
    </pre>

    <div class="card rounded-custom shadow border-0 p-4 flex-column justify-content-center">
      <h3 class="mb-4 fw-normal text-center">Valutazione fase precedente</h3>
      <Multiselect
        :options="failed"
        @selected="readMotivationIdSelected"
        :showAlert="alert"
        label="Selezionare il problema riscontrato">
      </Multiselect>
      <Note class="mt-3" label="Nota:" @message="readNota"></Note>
      <button class="btn btn-violet text-uppercase mt-4" @click="storeFeedbackNegative()">
        conferma valutazione
      </button>
    </div>

  </Empty>

</template>

<script>
import {reactive, ref, toRaw, watch, onMounted, computed, inject} from "vue";
import { Tooltip } from "bootstrap";
import moment from "moment";
import _head from "lodash/head";
import _find from "lodash/find";
import {hideModal} from '@/use/modal/hide';

import {fetchTemporaryUrl} from "@/use/repositories/assets/fetchTemporaryUrl";
import {fetchByType} from '@/use/repositories/feedbacks/fetchByType';
import { update as updateTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/update";
import {store as storeFeedback} from "@/use/repositories/feedbacks/store";
import {getFolderNameIconModelBySlug} from "@/use/utilities/getFolderNameIconModelBySlug";
import {getFileNameIconModelBySlug} from "@/use/utilities/getFileNameIconModelBySlug";

import Modal from '@/components/Scene/Modal';
import Empty from '@/components/Modal/Empty';
import Note from "@/components/general/Note";
import Multiselect from "@/components/Multiselect";

export default {
  name: "RenderDay",
  components: {
    Modal,
    Empty,
    Note,
    Multiselect,
  },
  props: [
    'data',
    'afterToday'
  ],
  emits: [
    'filesReadyToDownload',
  ],
  setup(props, {emit}) {
    const showData = ref(false);
    const filesReadyForDownload = ref([]);
    const showModal = ref(false);
    const scene = reactive({
      id: null,
      modal_id: null,
      title: null,
      subtitle: null,
      decline_label: null,
      temporary_url: null,
      child_internal_phase_id: null,
      test_work_queue_phase_id: null,
      child_id: null,
    });
    const showModalFeedback = ref(false);
    let failed = ref({});
    const motivations = reactive({ ids: [] });
    const alert = ref(false);
    const nota = ref(null);
    const { assetsType, states } = inject("constants");

    onMounted(() => {
      createTooltips();
    });

    // Computed is checked if all files are checked.
    const isDayChecked = computed(() => {
      let checked = true;
      Object.keys(props.data.works).forEach((key) => {
        if (props.data.works[key].length > 0) {
          props.data.works[key].forEach((el) => {
            if (el.assets.length > 0) {
              el.assets.forEach((file) => {
                if (!file.checked && file.state === "pending") {
                  checked = false;
                }
              });
            }
          });
        }
      });

      return checked;
    });

    // Remember checked all.
    watch(() => props.data, () => {
      // Regenerate tooltip.
      createTooltips();
    });

    // Create tooltip.
    const createTooltips = () => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
      });
    };

    // Get file name.
    const getFileName = (media) => {
      return _find(media).file_name;
    };

    // Check is today or after today.
    const isTodayOrAfterToday = (momentObj) => {
      return moment().isSameOrBefore(momentObj, 'day');
    }

    // Massive selection.
    const massiveSelection = async () => {
      // Toggle checked.
      let setCheck = !isDayChecked.value;
      // Clear array.
      filesReadyForDownload.value = [];

      Object.keys(props.data.works).forEach((key) => {
        if (props.data.works[key].length > 0) {
          props.data.works[key].forEach((el) => {
            if (el.assets.length > 0) {
              el.assets.forEach((file) => {
                if (file.state === "pending") {
                  // Not checked if already checked.
                  if (file.checked === setCheck) return;
                  // Toggle checked.
                  file.checked = setCheck;
                  filesReadyForDownload.value.push(file);
                }
              });
            }
          });
        }
      });

      emit("filesReadyToDownload", toRaw(filesReadyForDownload.value));
    }

    // Select single file.
    const selectedCurrent = async (asset, day) => {
      filesReadyForDownload.value = [];
      let isAllChecked = true;

      filesReadyForDownload.value.push(asset);
      asset.checked = !asset.checked;

      // Multi data structure.
      Object.values(day.works).forEach((works) => {
        works.forEach((data) => {
          data.assets.forEach((asset) => {
            if (asset.checked === false) {
              return isAllChecked = false;
            }
          });
        });
      });

      // Dynamic all selected icon.
      day.checked = isAllChecked;

      emit('filesReadyToDownload', toRaw(filesReadyForDownload.value));
    }

    const modalScene = async (asset) => {
      // Set data for modal.
      scene.id = asset.id;
      scene.modal_id = asset.id;
      scene.decline_label = "chiudi";
      scene.child_internal_phase_id = asset.child_internal_phase_id;
      scene.test_work_queue_phase_id = asset.test_work_queue_phase_id;
      scene.child_id = asset.child_id;

      // Fetch temporary url.
      await fetchTemporaryUrl(asset.id).then(async (response) => {
        scene.temporary_url = response.temporary_url;
        showModal.value = true;
      });
    }

    const closeModal = async (value) => {
      showModal.value = value;
    }

    const countAssetsByState = (assets, state) => {
      let count = 0;
      assets.forEach((obj) => {
        if (obj.state.toLowerCase() === state) {
          count++;
        }
      });

      return count;
    };

    const countAssetsByStateInMultidata = (el, state) => {
      //console.log(toRaw(el), state);
      let count = 0;
      toRaw(el).forEach((obj) => {
        if (_head(obj.assets).state.toLowerCase() === state) {
          count++;
        }
      });

      return count;
    };

    const filterAssetsByState = (assets, state) => {
      return assets.filter((asset) => {
        return asset.state === state;
      });
    };

    const afterTodayFilterCountAssetsByState = (data, state) => {
      let count = 0;

      Object.values(data.works).forEach((works) => {
        works.forEach((data) => {
          data.assets.forEach((asset) => {
            if (asset.state.toLowerCase() === state) {
              count++;
            }
          });
        });
      });

      return count;
    };

    const getPriorityDate = (works) => {
      return _head(_find(works)).delivery_date;
    }

    const showFeedback = () => {
      showModal.value = false;
      hideModal(scene.modal_id);
      // Fetch feedbacks.
      fetchByType('rating', {
        action: 'with_feedbacks',
        internal_phase_id: scene.child_internal_phase_id,
        state_slug: states.NEGATIVE_FEEDBACK,
      }).then((response) => {
        failed.value = response.feedbacks;
      });
      showModalFeedback.value = true;
    }

    const readMotivationIdSelected = (value) => {
      motivations.ids = toRaw(value);
    };

    const storeFeedbackNegative = async () => {
      if (motivations.ids.length === 0) {
        return (alert.value = true);
      }
      // Set feedback.
      await storeFeedback({
        child_test_work_queue_phase_id: [scene.child_id],
        state: "completed",
        note: nota.value,
        is_iso: Boolean(false),
        test_work_queue_phase_id: scene.test_work_queue_phase_id,
        feedback_id: _head(motivations.ids),
        action: "without_media",
      }).then(async () => {
        // Update state test work queue phase.
        await updateTestWorkQueuePhase({
          source_ids: [scene.child_id],
          state: "failed",
          action: "update"
        }).then(async () => {
          // TODO: Remove asset
        });
      });
    }

    const readNota = (value) => {
      nota.value = value;
    }

    const destroyModalFeedback = (value) => {
      showModalFeedback.value = value;
    }

    return {
      moment,
      showData,
      isTodayOrAfterToday,
      massiveSelection,
      selectedCurrent,
      filesReadyForDownload,
      showModal,
      closeModal,
      modalScene,
      scene,
      getFileName,
      countAssetsByState,
      countAssetsByStateInMultidata,
      filterAssetsByState,
      afterTodayFilterCountAssetsByState,
      isDayChecked,
      getPriorityDate,
      showModalFeedback,
      showFeedback,
      readMotivationIdSelected,
      storeFeedbackNegative,
      readNota,
      failed,
      alert,
      destroyModalFeedback,
      getFolderNameIconModelBySlug,
      getFileNameIconModelBySlug,
      assetsType,
    }
  }
}
</script>

<style scoped>
  .day {
    border-right: 3px solid #725D82;
    width: 300px;
  }
  .checkbox:hover,
  .file:hover {
    cursor: pointer;
  }
</style>
