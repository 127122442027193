import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function update(params) {
  const axios = axiosInstance().primoLab;
  let media = [];

  await axios
    .put(`/media`, params)
    .then(async (response) => {
      media = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { media };
}
