<script>
import { inject, computed } from 'vue';
import _ from 'lodash';

export default {
  name: 'RenderAssetsByPrescription',
  props: {
    assets: { type: Array, required: true },
  },
  setup(props) {
    const { states } = inject("constants");

    const structurePrescriptions = arrayAssets => _.chain(arrayAssets)
      .groupBy('prescription_id')
      .map((value) => ({
        number_text: _.head(value).number_text,
        delivery_date: _.head(value).delivery_date,
        id: _.head(value).id,
        first_name: _.head(value).first_name,
        last_name: _.head(value).last_name,
        device: _.head(value).prescription_test?.device?.name,
        assets: value
      }))
      .value();

    const getStateTranslate = (slug) => {
      switch (slug) {
        case states.PENDING:
          return 'in attesa';
        case states.IN_THE_WORKS:
          return 'in lavorazione';
        case states.COMPLETED:
          return 'completato';
      }
    }

    const getIconByState = (slug) => {
      switch (slug) {
        case states.PENDING:
          return 'consulting/pending-violet';
        case states.IN_THE_WORKS:
          return 'gear-violet';
        case states.COMPLETED:
          return 'like-green';
      }
    }

    const allDataFilteredByKey = computed(() => structurePrescriptions(props.assets));

    return { getStateTranslate, getIconByState, allDataFilteredByKey }
  },
};
</script>

<template>
  <div v-for="prescription in allDataFilteredByKey" :key="prescription.id" class="card mb-3">
    <div class="card-header">
    </div>
    <div class="card-body">
      <h5 class="card-title">
        {{ prescription.device ?? 'No Tag' }} | {{ prescription.number_text }} - {{ prescription.first_name }} {{ prescription.last_name }}
      </h5>
      <small>{{ prescription.delivery_date }}</small>
      <ul class="card-text">
        <li v-for="asset in prescription.assets" :key="asset.id" class="d-flex">
          <div class="me-3">
            <small>{{ asset.delivery_date }}</small>
          </div>
          <div class="me-3">
            <span>Materiale:</span>
            <span class="fw-bold">{{ asset.material }}</span>
          </div>
          <div class="me-3">
            <span>Colore:</span>
            <span class="fw-bold">{{ asset.code }}</span>
          </div>
          <div class="me-3">
            <span>Stato:</span>
            <span class="fw-bold">{{ getStateTranslate(asset.state) }}</span>
            <img
              class="ms-3"
              :src="require(`@/assets/icons/${getIconByState(asset.state)}.svg`)"
              width="25"
              :alt="`icon-${asset.state}-state`"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
