<template>
  <div class="d-flex">

    <div class="d-flex flex-column justify-content-between">
      <Brand width="140" />
      <div class="btn btn-violet text-uppercase d-flex mt-2"
           @click="$router.push({ name: 'ConsultingSearch' })">
        <img class="me-2" src="@/assets/icons/search-white.svg" width="20" alt="bx-search">
        <small>consultazione</small>
      </div>
      <div class="btn btn-violet text-uppercase d-flex mt-2"
           @click="$router.push({ name: 'CamSummary' })">
        <img class="me-2" src="@/assets/icons/printer-white.svg" width="20" alt="printer-white">
        <small>check-in</small>
      </div>
    </div>

    <div class="d-flex shadow rounded-custom bg-white align-items-center w-100 p-3 overflow-auto mx-3">
      <template v-for="(item, index) in $props.data" :key="index">
        <div class="material text-gray d-flex px-4 align-items-baseline flex-shrink-0" @click="selected(index)" :class="{ 'scale': item.selected }">
            <span class="material-text d-flex text-uppercase fs-4" :class="{ 'text-violet': item.selected }">
              <span class="me-1" :class="{ 'text-decoration-underline': item.selected }">{{ item.material }}</span> ({{ countToWorkInTheDay(item.toWorkInTheDay) }})
            </span>
          <span class="count-all fs-5">({{ countToWork(item.toWork) }})</span>
        </div>
      </template>
    </div>

    <div class="d-flex flex-wrap">
      <div class="d-flex">
      <!-- Warehouse link -->
        <button @click="redirectToWarehouseDashboard()" class="btn btn-violet me-2 rounded-custom w-50">
          <img src="@/assets/icons/shopping-cart.svg" width="60" alt="shopping-cart">
        </button>

      <!-- In the works link -->
        <router-link to="/cam/in-the-works" class="btn btn-violet rounded-custom d-flex align-items-center justify-content-center w-50 me-2">
          <img src="@/assets/icons/view-list.svg" width="40" alt="view-list">
        </router-link>

        <!-- Device consulting -->
        <router-link to="/device-consulting" class="btn btn-violet rounded-custom d-flex align-items-center justify-content-center w-50">
          <img src="@/assets/icons/digital-label.svg" width="50" alt="shopping-cart" style="filter: brightness(3);">
        </router-link>
      </div>
      <GlobalSearch
        placeholder="Ricerca Prescrizione"
        :debounceTime="800"
        :minCharacters="3"
        :reset="clearGlobalSearch"
        ref="camGlobalSearch"
        @toSearch="toSearch">
      </GlobalSearch>
    </div>
  </div>
</template>

<script>
import {onMounted, watch, toRaw, ref} from "vue";
import {useRouter} from "vue-router";
import _head from "lodash/head";

import Brand from "../Brand";
import GlobalSearch from "../GlobalSearch.vue";

export default {
  name: "Header",
  components: {
    Brand,
    GlobalSearch
  },
  props: [
    'data',
    'reset',
    'clearGlobalSearch',
  ],
  emits: [
    'materialType',
    'removeMaterial',
    'toSearch'
  ],
  setup(props, {emit},) {
    const router = useRouter();
    const camGlobalSearch = ref(null);

    onMounted(() => {
      if (props.data.length > 0) {
        activeFirstElement();
        // Emit material type.
        emit('materialType', _head(props.data).material);
      }
      setFocusInput();
    });

    watch(() => props.clearGlobalSearch, () => {
      setFocusInput();
    })

    watch(() => props.reset, (value) => {
      console.log("IDS TO REMOVE: " + toRaw(value));

      props.data.forEach((item, index) => {
        // Sync assets.
        syncElements(item.toWork);
        syncElements(item.toWorkInTheDay);

        if (item.toWork.length === 0 && item.toWorkInTheDay.length === 0) {
          // Remove material type.
          emit('removeMaterial', index);
        }
      });

      if (props.data.length > 0) {
        if (props.data.some((item) => item.selected === true)) {
          console.log("MATERIAL SELECTED EXIST");
          console.log(props.data[props.data.findIndex((item) => item.selected === true)]);
          emit("materialType", props.data[props.data.findIndex((item) => item.selected === true)].material);
        } else {
          console.log("MATERIAL SELECTED NOT EXIST");
          activeFirstElement();
          emit("materialType", _head(props.data).material);
        }
      }
    });

    const countAssets = (array) => {
      if (array.length === 0) {
        return 0;
      }

      let count = 0;
      array.forEach((el) => {
        count = count + el.assets.length;
      });

      return count;
    }

    const countToWorkInTheDay = (array) => {
      return countAssets(array);
    }

    const countToWork = (array) => {
      return countAssets(array);
    }

    const activeFirstElement = async () => {
      _head(props.data).selected = true;
    }

    const selected = async (indexSelected) => {
      // Reset selected.
      props.data.forEach((el, index) => {
        if (index === indexSelected) {
          // Set selected.
          return el.selected = true;
        }
        return el.selected = false;
      });

      // Emit material type.
      emit('materialType', props.data[indexSelected].material);
    }

    const syncElements = (array) => {
      props.reset.forEach((id) => {
          array.forEach((obj, index) => {
            let indexExists = obj.assets.findIndex((asset) => asset.id === id);
            if (indexExists !== -1) {
              console.log("REMOVE ASSET");
              array[index].assets.splice(indexExists, 1);
            }
            // Day empty.
            if (array[index].assets.length === 0) {
              console.log("REMOVE DAY");
              array.splice(index, 1);
            }
          })
        });
    }

    const redirectToWarehouseDashboard = () => {
      return router.push({ name: "Dashboard" });
    }
    const toSearch = (value) => {
      emit('toSearch', value);
    }

    const setFocusInput = () => {
      setTimeout(() => {
        camGlobalSearch.value.globalSearch.focus();
      }, 500);
    };

    return {
      selected,
      countToWorkInTheDay,
      countToWork,
      redirectToWarehouseDashboard,
      toSearch,
      camGlobalSearch
    }
  }
}
</script>

<style scoped>
.material-text {
  font-size: 1.7em;
}
.material:hover {
  cursor: pointer;
}
.count-all {
  font-size: 1.5em;
}
.scale {
  transform: scale(1.2);
  transition: all .2s ease-in-out;
}
.text-gray {
  color: darkgray;
}
</style>
