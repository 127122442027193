<template>
  <div class="checkin container-fluid p-5 position-relative vh-100">

    <div class="d-flex justify-content-center">
      <Brand :width="150" withChat />
      <Logout class="position-absolute start-0 top-0"/>
    </div>

    <Button label="consultazione" style="right: 50px;" route-name="ConsultingSearch">
      <img class="me-2" src="@/assets/icons/search-white.svg" width="20" alt="bx-search">
    </Button>

    <!-- Manager counters -->
    <template v-if="hasRole('manager')">
        <div class="d-flex flex-wrap px-2 justify-content-center mb-5">
          <Counter
            class="ms-2 mt-2"
            :show-loading="spinnerWarningInfo"
            :counter="warningInfoDataCounter"
            :label="'Info clinica / Aiuto'"
            :stateSlug="'info'"
            :color="'#AF0B55'"
            :preSelected="true"
            :readOnlyMode="true"
          />
          <Counter
            class="ms-2 mt-2"
            :show-loading="spinnerInTheWorks"
            :counter="inTheWorksDataCounter"
            :label="'In lavorazione'"
            :stateSlug="'in-the-works'"
            :color="'#9D9D9D'"
            :preSelected="true"
            :readOnlyMode="true"
          />
          <Counter
            class="ms-2 mt-2"
            :show-loading="spinnerAssign"
            :counter="assignDataCounter"
            :label="'Assegnate'"
            :stateSlug="states.ASSIGNED"
            :color="'#FFC107'"
            :preSelected="true"
            :readOnlyMode="true"
          />
          <Counter
            class="ms-2 mt-2"
            :show-loading="spinnerFailed"
            :counter="failedDataCounter"
            :label="'Fallite'"
            :stateSlug="[states.ISO_FAILED, states.MANAGER_FAILED]"
            :color="'#E97B86'"
            :preSelected="true"
            :readOnlyMode="true"
          />
          <MainButton
            class="ms-2 mt-2"
            :showBell="dataExists"
            :showChevronRight="true"
          />
        </div>
    </template>

    <template v-if="showPendingPhases">
      <div class="container d-flex overflow-auto mb-2 py-2">
        <div class="m-auto d-flex">
          <BoxInformation
            v-for="(assignedTestWorkQueuePhase, index) in orderedAssignedTestWorkQueuePhases"
            :key="index"
            class="m-2"
            :test-work-queue-phase-assigned="assignedTestWorkQueuePhase"
            @paste="fillNumberText"
            @not-found="openMissingModal(assignedTestWorkQueuePhase.test_work_queue)"
            @technician-suspend="openConfirmModal"
          />
      </div>
    </div>
    </template>
    <div class="row justify-content-center">
      <div class="col-xxl-3 offset-xxl-3 col-xl-4 offset-xl-4 col-md-12 col-sm-12">
        <div class="card rounded-custom shadow border-0 p-3 mb-5">
          <div class="card-body">
            <h2 class="mb-4 fw-normal text-center">Lavorazione</h2>
            <form class="mb-3" onsubmit="return false;">
              <label class="form-label" for="prescription">Codice prescrizione</label>
              <div class="input-group">
                <template v-if="useBarcode">
                  <span class="input-group-text" id="keyboard" @click="handleManualCheckin('prescriptionScanManual')">
                    <img src="@/assets/icons/logistic-checkin/keyboard.svg" width="30" alt="icon-keyboard">
                      </span>
                        <input
                          type="text"
                          id="scanBarCode"
                              autocomplete="off"
                              class="form-control"
                              placeholder="Inserisci il codice a barre"
                              v-model="prescriptionCode"
                              @input="onInputChange"
                              @keyup.enter="onInput"
                            />
                </template>
                <template v-else>
                        <span class="input-group-text" id="scanner-barcode" @click="handleManualCheckin('scanBarCode')">
                        <img src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="30" alt="icon-scanner-barcode">
                      </span>
                      <input
                        type="text"
                        autocomplete="off"
                        id="prescriptionScanManual"
                        v-model="prescriptionCode"
                        class="form-control"
                        :class="[{ 'is-valid': prescriptionIsValid, 'is-invalid': prescriptionIsInvalid }]"
                        placeholder="Scansiona il codice a barre"
                        @input="onInput"
                        />
                </template>
              </div>

              <div class="m-3" v-if="isAllowedToDoIsoOut">
                <Toggle @change="handleIsoForcement" v-model="isIsoForcement" />
                  <label class="ms-2 fw-bold">Richiesta ISO-Out</label>
              </div>

              <div v-if="assignedTestWorkQueuePhase" class="valid-feedback" :class="{ 'd-block': prescriptionIsValid }">
                Codice prescrizione valido
              </div>
              <div class="invalid-feedback" :class="{ 'd-block': prescriptionIsInvalid }">
                Codice prescrizione non valido
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-12 col-sm-12">
        <template v-if="showOnHoldPhases">
          <OnHoldPhases
            :test-work-queue-phases="onHoldTestWorkQueuePhases"
            @resume="resumeTesWorkQueuePhase"
          />
        </template>
      </div>
    </div>
  </div>

  <Standard
    v-if="showMissingModal"
    :size="'xl'"
    :modal_id="'missing_modal'"
    :title="'Casella non trovata'"
    :subtitle="'Puoi ricercarla, o sospendere la casella selezionata'"
    :decline_label="null"
    :lightLayout="false"
    :options="{ keyboard: false, backdrop: 'static' }"
    style="z-index: 999991"
    @hidden="closeMissingModal"
    >
    <div class="pt-4" style="min-height: 60vh;">
      <MissingWorksWizard
        :can-suspend="true"
        :selectableData="selectableTestWorkQueuePhases"
        :open="showMissingModal"
        :selected="missingModalTwq"
        @suspend="storeTestWorkQueueSuspension"
        >
      </MissingWorksWizard>
    </div>
  </Standard>

  <Standard v-if="showConfirmModal"
    modal_id="confirmSuspendModal"
    :title="`Stai per riassegnare la prescrizione n. ${selectedWork?.test_work_queue.prescription_test?.prescription?.number_text}`"
    decline_label="chiudi"
    :lightLayout="false"
    :options="{ keyboard: false, backdrop: 'static' }"
    @hidden="closeConfirmModal"
    >
       <Note class="mt-3"
        label="Motivazione:"
        @message="handleSeniorityReassignmentNote"
        />
        <div class="d-flex align-items-center justify-content-center flex-column">
          <button type="button" :disabled="! seniorityReassignmentNote" class="btn btn-lg btn-violet text-uppercase mt-4" @click="handleSeniorityReassignment(selectedWork)">
            conferma
          </button>
        </div>
</Standard>

</template>

<script>
import {
  onMounted,
  ref,
  getCurrentInstance,
  onUnmounted,
  computed,
  inject,
  nextTick,
} from "vue";

import { hideModal as closeModal } from "@/use/modal/hide";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import _head from "lodash/head";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _remove from "lodash/remove";
import _map from "lodash/map";
import debounce from "lodash/debounce";
import moment from "moment";
import Toggle from '@vueform/toggle';

import Brand from "@/components/Brand";
import BoxInformation from "@/components/Checkin/BoxInformation";
import OnHoldPhases from "@/components/Checkin/OnHoldPhases";
import Logout from "@/components/Logout";
import Counter from "@/components/Manager/Counter";
import MainButton from "@/components/Manager/MainButton";
import Button from "@/components/general/Button";
import MissingWorksWizard from "@/components/devices/MissingWorksWizard.vue";
import Standard from "@/components/Modal/Standard";

import { formatDate } from "@/use/utilities/time/formatDate";
import { useToast } from "vue-toastification";
import { assign } from "@/use/repositories/testWorkQueuePhase/assign";
import { fetchBySlug as fetchTestWorkQueuePhaseBySlug } from "@/use/repositories/testWorkQueuePhase/fetchBySlug";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import { changeState as changeStateUser } from '@/use/repositories/users/changeState';
import { isTechnicianIso } from "@/use/utilities/isTechnicianIso";
import { hasRole } from "@/use/utilities/users/hasRole";
import { update as updateTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/update";
import { fetchByType } from '@/use/repositories/feedbacks/fetchByType';
import { store as storeFeedback } from "@/use/repositories/feedbacks/store";
import managerCounters from "@/use/manager/useCounters";
import Note from '@/components/general/Note';

export default {
  name: "Checkin",
  components: {
    Button,
    Brand,
    BoxInformation,
    OnHoldPhases,
    Logout,
    Counter,
    MainButton,
    MissingWorksWizard,
    Standard,
    Toggle,
    Note,
},
  setup() {
    const i18n = useI18n();
    const showMissingModal = ref(false)
    const missingModalTwq = ref(null);
    const closeMissingModal = () => {
      closeModal('missing_modal');
      showMissingModal.value = false;
      missingModalTwq.value = null;
    }
    const openMissingModal = (testWorkQueue) => {
      missingModalTwq.value = testWorkQueue.prescription_test.id;
      showMissingModal.value = true;
    }

    const showConfirmModal = ref(false);
    const selectedWork = ref(null);

    const openConfirmModal = (value) => {
      selectedWork.value = value;
      showConfirmModal.value = true;
    }

    const closeConfirmModal = () => {
      showConfirmModal.value = false;
      closeModal('showConfirmModal')
    }

    const router = useRouter();
    const { states, departments } = inject("constants");
    const toast = useToast();
    const store = useStore();
    const prescriptionCode = ref("");
    const assignedTestWorkQueuePhase = ref({});
    const assignedTestWorkQueuePhases = ref([]);
    const failedTestWorkQueuePhase = ref([]);
    const onHoldTestWorkQueuePhases = ref([]);
    const showOnHoldPhases = ref(false);
    const showPendingPhases = ref(false);
    const prescriptionIsValid = ref("");
    const prescriptionIsInvalid = ref("");
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const countToResume = ref(0);
    const isResume = ref(false);
    const seniorityReassignmentNote = ref("");

    const orderedAssignedTestWorkQueuePhases = computed(() => {
      return _sortBy(assignedTestWorkQueuePhases.value, 'test_work_queue.delivery_date');
    });

    const isIsoForcement = ref(store.state.mainRole.isISoOutForcementActive ?? false);

    const handleIsoForcement = (value) => {
      store.commit('IS_ISO_OUT_FORCEMENT_ACTIVE', value);
    }

    const useBarcode = ref(false);
    const isoTechnician = isTechnicianIso();
    console.log(`IS TECHNICIAN ISO: ${isoTechnician}`);
    // Manager data.
    const { spinnerWarningInfo,
            spinnerInTheWorks,
            spinnerAssign,
            spinnerFailed,
            warningInfoDataCounter,
            inTheWorksDataCounter,
            assignDataCounter,
            failedDataCounter,
            dataExists,
            manageManagerCounters
    } = managerCounters();

    // Set focus on input by id and clear value.
    const setFocusInput = async (id) => {
      prescriptionCode.value = "";
      nextTick(() => {
        const input = document.getElementById(id);
        if (input !== null) {
          input.focus();
        }
      });
    };

    const handleManualCheckin = (focusId) => {
      setFocusInput(focusId);
      useBarcode.value = !useBarcode.value;
    }

    const onInputChange = (input) => {
      prescriptionCode.value = input.target.value;
    }

    const handleSeniorityReassignment = (testWorkQueuePhase) => {
      fetchByType('reassignment-for-seniority', {
        action: 'with_feedbacks',
        internal_phase_id:  testWorkQueuePhase.internal_phase.id,
        state_slug: 'on-hold',
      }).then((response) => {
          if (response.feedbacks.length) storeFeedback({
              test_work_queue_phase_id:  parseInt(testWorkQueuePhase.id),
              state: "on-hold",
              is_iso: Boolean(false),
              note: seniorityReassignmentNote.value,
              feedback_id: _head(response.feedbacks).value,
              action: 'without_media',
            }).then(async () => {
              // Reset note and close modal
              seniorityReassignmentNote.value = "";
              closeConfirmModal();

              _remove(assignedTestWorkQueuePhases.value, (element) => { return element.id === testWorkQueuePhase.id });
              closeMissingModal();
              // Refresh test work queue phase in state "on-hold";
              await getTestWorkQueuePhase();
          });

          else toast.error('Nessun feedback. Contattare il servizio tecnico');
        });
    }

    const handleSeniorityReassignmentNote = (text) => {
      seniorityReassignmentNote.value = text;
    }

    const selectableTestWorkQueuePhases = computed( () => {
      let testWorkQueuePhases = _filter(assignedTestWorkQueuePhases.value, function (phase) {
        return phase.test_work_queue.prescription_test.device !== null;
      });

      testWorkQueuePhases = _sortBy(testWorkQueuePhases, [(o) => {
        moment(o.test_work_queue.delivery_date).format("DD/MM/YYYY HH:mm:ss");
      }]);

      return _map(testWorkQueuePhases, (value) => {
        return {
          id: value.id,
          prescription_test_id: value.test_work_queue.prescription_test.id,
          name: value.test_work_queue.prescription_test.prescription.number_text,
          first_name: value.test_work_queue.prescription_test.prescription.customer.first_name,
          last_name: value.test_work_queue.prescription_test.prescription.customer.last_name,
          delivery_date: formatDate(value.test_work_queue.delivery_date),
          internal_phase: value.internal_phase,
          device_id: value.test_work_queue.prescription_test?.device?.id,
          device_name: value.test_work_queue.prescription_test?.device?.name,
        };
      });
    })

    // Pusher.
    const subscribe = (channelName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));

      const callback = (eventName, data) => {
        switch (eventName) {
          case "assign_phase":
            if (! assignedTestWorkQueuePhases.value.some(element => element.id === data.id)) {
              assignedTestWorkQueuePhases.value.push(data);
              toast.success('Nuova casella assegnata!');
            }
            showPendingPhases.value = true;
            focusPrescription();
            break;
          case "manager_change_phase_state":
            manageManagerCounters(data.state.slug, 'SUM');
            break;
        }
        console.log(`bind global channel: The event ${eventName} was triggered with data ${JSON.stringify(data)}`);
      };

      // Bind to all events on the channel.
      channel.bind_global(callback);

    };

    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    onMounted(() => {
      // Subscribe to channel.
      subscribe(`presence-user.${store.state.user.id}`);
      getAssignedTestWorkQueuePhases();
      getTestWorkQueuePhase();
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel(`presence-user.${store.state.user.id}`);
    });

    const selectAssignedWork = async (id) => {
      assignedTestWorkQueuePhase.value = assignedTestWorkQueuePhases.value.find((phase) => {return  phase.id === id });
    }

    const getAssignedTestWorkQueuePhases = async () => {
      await fetchTestWorkQueuePhaseBySlug(store.state.user.id, [states.ASSIGNED], store.state.mainRole.team.name)
        .then(response => {
          assignedTestWorkQueuePhases.value =  response.user.test_work_queue_phases;
        })
    }

    const getTestWorkQueuePhase = async () => {
      await fetchTestWorkQueuePhaseBySlug(
        store.state.user.id,
        [states.ON_HOLD],
        store.state.mainRole.team.name
      ).then(async (response) => {
        console.log(`COUNT USER TEST WORK QUEUE ${response.user.test_work_queue_phases.length}`);
        response.user.test_work_queue_phases.forEach((testWorkQueuePhase) => {
          console.log(`ID: ${testWorkQueuePhase.id} - NUMBER TEXT: ${testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text} - DEPARTMENT: ${testWorkQueuePhase.department.slug} - STATE: ${testWorkQueuePhase.state.slug} - DELIVERY DATE: ${testWorkQueuePhase.test_work_queue.delivery_date}`)
        });

        let existsTestWorkQueuePhases = (response.user.test_work_queue_phases.length > 0);

        if (existsTestWorkQueuePhases) {
          // In on-hold state.
          onHoldTestWorkQueuePhases.value = response.user.test_work_queue_phases.filter((testWorkQueuePhase) => {
            return testWorkQueuePhase.state.slug === states.ON_HOLD;
          });

          if (onHoldTestWorkQueuePhases.value.length > 0) {
            showOnHoldPhases.value = true;
            // Count on-hold test work queue phase will be to resume.
            onHoldTestWorkQueuePhases.value.forEach((item) => {
              if (Object.keys(item.feedbacks).length === 0) {
                return;
              }
              countToResume.value += _head(item.feedbacks)?.feedback?.types.some(
                item => [
                  'resumable',
                  'missing-box',
                ].includes(item.slug));
            });
          }
        }
        showPendingPhases.value = true;
      });
    };

    const assignNewTestWorkQueuePhase = async (action, params = {}) => {
      const data = {
        department_slug: store.state.mainRole.team.name,
        action: action,
        ...params,
      };

      await assign(data).then(async (response) => {
        if (action !== 'by_device_name') {
          assignedTestWorkQueuePhase.value = _head(response.assignedTestWorkQueuePhase);
          showPendingPhases.value = true;
        }
      }).catch((errors) => {
        if (onHoldTestWorkQueuePhases.value.length > 0) {
          showOnHoldPhases.value = true;
        }

        throw new Error(_head(errors));
      });
    };

    const setInvalidPrescription = () => {
      console.log("PRESCRIPTION CODE IS INVALID");
      prescriptionIsInvalid.value = true;

      setTimeout(() => {
        resetValidationMessages();
        prescriptionCode.value = "";
      }, 2000);
    }

    const isAllowedToDoIsoOut = computed(() => {
      return [departments.ISO_FISSA, departments.ISO_MOBILE].includes(store.state.mainRole.team.name);
    });

    const onInput = debounce(async () => {
      if (prescriptionCode.value === "") {
        resetValidationMessages();
        return;
      }

      const testWorkQueuePhases = findByBarCode(assignedTestWorkQueuePhases.value, prescriptionCode.value);

      prescriptionIsValid.value = !! testWorkQueuePhases.length;

      if (prescriptionIsValid.value) {
        await handleValidPrescription(testWorkQueuePhases);
      } else {
        await handleInvalidPrescription();
      }
    }, 1000);

    const handleValidPrescription = async (testWorkQueuePhases) => {
      if (testWorkQueuePhases.length > 1) {
        toast.error(i18n.t('More workings were found. Select it from the list or scan the individual prescription'));
      } else {
        assignedTestWorkQueuePhase.value = assignedTestWorkQueuePhases.value.find(phase => phase.id === _head(testWorkQueuePhases).id);

        await goNext();
      }
    }

    const findByBarCode = (arraySource, textInput) => {

      if (isNumeric(textInput)) return findByDevice(arraySource, textInput);

      return findByNumberText(arraySource, textInput);
    };

    const findByDevice = (arraySource, text) => {
      const filteredWorkQueues = arraySource.filter(item => matchDeviceName(item, text));

      return filteredWorkQueues;
    };

    const findByNumberText = (arraySource, text) => {
      const testWorkQueuePhaseToSelect = arraySource.find(
        item => item.test_work_queue.prescription_test.prescription.number_text === text
      );

      if (testWorkQueuePhaseToSelect) {
        return [testWorkQueuePhaseToSelect]
      }

      return [];

      // if (testWorkQueuePhaseToSelect) {
      //     const device = testWorkQueuePhaseToSelect.test_work_queue.prescription_test.device;

      //     if (device) return arraySource.filter(item => matchDeviceName(item, device.name));

      //     return [testWorkQueuePhaseToSelect];
      //   }

      //   return [];
    };

    const matchDeviceName = (item, name) => item.test_work_queue.prescription_test?.device?.name === name;

    const handleInvalidPrescription = async () => {
      try {
        if (isNumeric(prescriptionCode.value)) {
          if (store.state.mainRole.team.name !== departments.PROGETTAZIONE) {
            await assignNewTestWorkQueuePhase("by_device_name", { device_name: prescriptionCode.value });
          } else {
            setInvalidPrescription();
            return;
          }
        } else {
          if (isAllowedToDoIsoOut.value && isIsoForcement.value) {
            await assignNewTestWorkQueuePhase("by_iso_out", { number_text: prescriptionCode.value });
          } else if (store.state.mainRole.team.name !== departments.PROGETTAZIONE) {
            await assignNewTestWorkQueuePhase("by_prescription_number_text", { number_text: prescriptionCode.value });
          } else {
            setInvalidPrescription();
            return;
          }
        }

        if (! prescriptionIsInvalid.value) {
          await goNext();
        }
      } catch (error) {
        setInvalidPrescription();
      }
    }

    const isNumeric = (textInput) => /^[+-]?\d+(\.\d+)?$/.test(textInput.trim());

    const resetValidationMessages = () => {
      prescriptionIsValid.value = "";
      prescriptionIsInvalid.value = "";
    }

    const handleErrors = () => {
      // Reset card details.
      showPendingPhases.value = false;
      showOnHoldPhases.value = false;
      // Reset validations colors.
      resetValidationMessages();
      // Reset input value.
      prescriptionCode.value = "";
    }

    const goNext = async () => {
      if (isFirstTestWorkQueuePhase()) {
        console.log("IS FIRST TEST WORK QUEUE PHASE");

        await updateTestWorkQueuePhase({
          source_ids: [assignedTestWorkQueuePhase.value.id],
          state: "in-the-works",
          action: "update",
        }).then(async () => {
          // Update state technician.
          await changeStateUser("working");

          // Update test work queue "in-the-work" state.
          updateTestWorkQueue({
            id: assignedTestWorkQueuePhase.value.test_work_queue.id,
            state_slug: "in-the-works",
            action: "update_state"
          }).then((response) => {
            console.log(`UPDATE TWQ ID: ${response.testWorkQueue.id} STATE IN ${response.testWorkQueue.state.slug}`);
          });

          // Redirect to iso-in.
          if (isTechnicianIso()) {
            await redirectToIsoIn();
            return;
          }

          await redirectToDashboard(assignedTestWorkQueuePhase.value.id);
        }).catch(() => {
          handleErrors();
        });

      } else {
        console.log("NO FIRST TEST WORK QUEUE PHASE");

        // Redirect on feedback by dynamic conditions.
        if (isTechnicianIso()) {
          console.log("NO FEEDBACK REDIRECT");

          await updateTestWorkQueuePhase({
            source_ids: [assignedTestWorkQueuePhase.value.id],
            state: "in-the-works",
            action: "update",
          }).then(async () => {
            await changeStateUser("working");
            await redirectToIsoIn();
          }).catch(() => {
            handleErrors();
          });
        } else if (isResume.value) {
          console.log("NO FEEDBACK REDIRECT IS RESUME");

          await updateTestWorkQueuePhase({
            source_ids: [assignedTestWorkQueuePhase.value.id],
            state: "in-the-works",
            action: "update",
          }).then(async () => {
            await changeStateUser("working");
            await redirectToDashboard(assignedTestWorkQueuePhase.value.id);
          }).catch(() => {
            handleErrors();
          });
        } else {
          // Info parents.
          let parentsIds = assignedTestWorkQueuePhase.value.parent_test_work_queue_phases.map((item) => item.id);
          let parentInternalPhase = _head(assignedTestWorkQueuePhase.value.parent_test_work_queue_phases);
          let parentInternalPhaseIsIso = [
            departments.ISO_MOBILE,
            departments.ISO_FISSA,
            departments.ISO_PREPARAZIONE,
            departments.ISO_PROGETTAZIONE,
          ].includes(parentInternalPhase.department.slug) || parentInternalPhase.state.slug === states.ISO_IN_SKIPPED;

          if (parentInternalPhaseIsIso) {
            await updateTestWorkQueuePhase({
              source_ids: [assignedTestWorkQueuePhase.value.id],
              state: "in-the-works",
              action: "update",
            }).then(async () => {
              await changeStateUser("working");
              console.log("REDIRECT ON DASHBOARD WHEN PARENT PHASE IS ISO-IN TYPE");
              await redirectToDashboard(assignedTestWorkQueuePhase.value.id);
            }).catch(() => {
              handleErrors();
            });
          } else {
            console.log("REDIRECT ON FEEDBACK");

            await router.push({
              name: "Feedback",
              query: {
                test_work_queue_id: assignedTestWorkQueuePhase.value.test_work_queue.id,
                test_work_queue_phase_id: assignedTestWorkQueuePhase.value.id,
                parent_test_work_queue_phase_id: _head(parentsIds), // TODO manage when feedback will be ready
                internal_phase_id: assignedTestWorkQueuePhase.value.internal_phase.id,
                parent_internal_phase_id: parentInternalPhase.internal_phase.id
              },
            });
          }
        }
      }
    };

    // Check if phase is first.
    const isFirstTestWorkQueuePhase = () => {
      return (assignedTestWorkQueuePhase.value.parent_test_work_queue_phases.length === 0);
    };

    const focusPrescription = async () => {
      prescriptionCode.value = "";
      const prescriptionInput = document.getElementById("prescriptionScanManual");
      prescriptionInput.focus();
    };

    const redirectToDashboard = async (testWorkQueuePhaseId) => {
      console.log("REDIRECT TO DASHBOARD");
      return await router.push({
        name: "Dashboard",
        params: {test_work_queue_phases_id: testWorkQueuePhaseId},
      });
    }

    const redirectToIsoIn = async () => {
      console.log("REDIRECT TO ISO IN DASHBOARD");
      return await router.push({
        name: "ConsultingDashboard",
          params: {
            prescription_id: assignedTestWorkQueuePhase.value.test_work_queue.prescription_test.prescription.id,
          },
          query: {
            read_only: false,
            test_work_queue_phase_id: assignedTestWorkQueuePhase.value.id,
          },
      })
    }

    const fillNumberText = (numberText) => {
      focusPrescription();
      prescriptionCode.value = numberText;
      onInput();
    }

    const resumeTesWorkQueuePhase = (testWorkQueuePhase) => {
      assignedTestWorkQueuePhases.value.push(testWorkQueuePhase);
      assignedTestWorkQueuePhase.value = testWorkQueuePhase;
      isResume.value = true;
      fillNumberText(testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text);
    }

    const storeTestWorkQueueSuspension = (testWorkQueuePhases, type = 'missing-box') => {
      testWorkQueuePhases.forEach(async (testWorkQueuePhase) => {
        fetchByType(type, {
        action: 'with_feedbacks',
        internal_phase_id: testWorkQueuePhase.internal_phase.id,
        state_slug: 'on-hold',
        }).then((response) => {
          if (response.feedbacks.length) storeFeedback({
              test_work_queue_phase_id:  parseInt(testWorkQueuePhase.id),
              state: "on-hold",
              is_iso: Boolean(false),
              note: seniorityReassignmentNote.value,
              feedback_id: _head(response.feedbacks).value,
              action: 'without_media',
            }).then(async () => {
              _remove(assignedTestWorkQueuePhases.value, (element) => { return element.id === testWorkQueuePhase.id });
              closeMissingModal();
              // Refresh test work queue phase in state "on-hold";
              await getTestWorkQueuePhase();
          });

          else toast.error('Nessun feedback. Contattare il servizio tecnico');
        });
      });
    }

    return {
      assignedTestWorkQueuePhase,
      assignNewTestWorkQueuePhase,
      prescriptionIsValid,
      prescriptionCode,
      onHoldTestWorkQueuePhases,
      failedTestWorkQueuePhase,
      showOnHoldPhases,
      showPendingPhases,
      onInput,
      prescriptionIsInvalid,
      fillNumberText,
      resumeTesWorkQueuePhase,
      countToResume,
      inTheWorksDataCounter,
      warningInfoDataCounter,
      failedDataCounter,
      assignDataCounter,
      dataExists,
      isoTechnician,
      states,
      spinnerWarningInfo,
      spinnerInTheWorks,
      spinnerAssign,
      spinnerFailed,
      useBarcode,
      handleManualCheckin,
      onInputChange,
      assignedTestWorkQueuePhases,
      selectAssignedWork,
      selectableTestWorkQueuePhases,
      openMissingModal,
      showMissingModal,
      closeMissingModal,
      missingModalTwq,
      openConfirmModal,
      showConfirmModal,
      closeConfirmModal,
      selectedWork,
      storeTestWorkQueueSuspension,
      orderedAssignedTestWorkQueuePhases,
      hasRole,
      isIsoForcement,
      handleIsoForcement,
      isAllowedToDoIsoOut,
      handleSeniorityReassignmentNote,
      seniorityReassignmentNote,
      handleSeniorityReassignment,
    };

  },
};
</script>

<style scoped lang="scss">
  .form-control.is-valid {
    &:hover {
      border-color: rgb(169, 223, 191) !important;
      box-shadow: 0 0 0 0.25rem rgb(169, 223, 191) !important;
      outline: 0 none !important;
    }
  }
  .form-control.is-invalid {
    &:focus {
      border-color: rgb(236, 112, 99) !important;
      box-shadow: 0 0 0 0.25rem rgb(236, 112, 99) !important;
      outline: 0 none !important;
    }
  }
</style>
