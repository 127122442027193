<script>
import { inject, reactive, ref, toRaw, watch } from 'vue';
import { downloadZipByIds } from "@/use/repositories/assets/downloadZipByIds";
import { print } from "@/use/repositories/devices/print";
import { update } from "@/use/repositories/media/update";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import _ from "lodash";

import Confirm from "@/components/Modal/Confirm.vue";
import RenderAssetsByPrescription from "@/components/cam/common/RenderAssetsByPrescription.vue";

export default {
  name: "Download",
  props: ['data', 'dataset'],
  emits: ['reset'],
  components: { Confirm, RenderAssetsByPrescription },
  setup(props, { emit }) {
    const { states, devicesType, departments } = inject("constants");
    const files = reactive({
      value: [],
    });
    const printers = ref();
    const confirmModal = reactive({
      isVisible: false,
      modalId: 'confirmDownload',
      title: 'Attenzione',
      subtitle: 'Le seguenti prescrizioni con relativo tag non risultano selezionate per il download.<br>Procedere comunque?',
      assets: [],
      confirmLabel: 'Si',
      declineLabel: 'No'
    });

    watch(() => props.data.length, () => {
      // Dynamic visualize on file to download.
      files.value = [];
      props.data.forEach((item) => {
        files.value.push(item);
      });
    });

    const printDigitalPrescriptions = async () => {
      const uniqueCombinations = _.chain(toRaw(files).value)
        .filter({ is_digital: 1, has_prints: false})
        .map(item => ({
          identifier: `${item.prescription_id}_${item.test_work_queue_id}`,
          prescription_id: item.prescription_id,
          test_work_queue_id: item.test_work_queue_id
        }))
        .uniqBy('identifier')
        .map(item => _.pick(item, ['prescription_id', 'test_work_queue_id']))
        .value();

      uniqueCombinations.forEach((item) => {
        // Print Label
        fetchAllTesWorkQueue({
          action: 'with_base_64_labels',
          ids: [
            item.test_work_queue_id,
          ]
        }, true)
          .then((response) => print({
            device_type_slug: devicesType.ANALOG_LABEL,
            department_slug: departments.CAM,
            data: _.head(response.testWorkQueue).raw_data,
          }));
      });
    }

    const isMultiplePrescriptions = async () => {
      const selectedIds = files.value.map(file => file.id);

      const exists = files.value.some(file => {
        const deviceId = file.prescription_test?.device?.id;

        if (!deviceId) return false;

        const matchingAssets = props.dataset.filter(({ prescription_test, id }) =>
          prescription_test?.device?.id === deviceId && !selectedIds.includes(id)
        );

        if (matchingAssets.length > 0) {
          confirmModal.assets = matchingAssets
          confirmModal.isVisible = true;
        }

        return !! matchingAssets.length;
      });

      return exists;
    }

    const checkAndConfirmDownload = async () => {
      const existsMultiPrescriptions = await isMultiplePrescriptions();

      if (existsMultiPrescriptions) return;

      return download();
    }

    const download = async () => {
      // Print digital prescription labels
      await printDigitalPrescriptions();

      // Map ids.
      const fileIdsToDownload = props.data.map((item) => item.id);

      // Digital media to rename
      const filesToRename = _.flatMap(
        _.filter(props.data, (item) =>
        _.isObject(_.get(item, 'prescription_test.device')) && item.is_digital === 1
        ),
        (item) => _.map(item.media, media => {
          return {
            media_uuid: media.uuid,
            file_name: `${item.is_digital ? 'D_': ''}${item.prescription_test?.device?.name}_${media.file_name}`,
          }})
      );

      if (filesToRename.length) {
        // Rename files.
        await update({
          action: "rename_media",
          media: filesToRename
        }).then(async (response) => {
          console.log(response);
        });
      }

      // Download zip.
      await downloadZipByIds(fileIdsToDownload, states.IN_THE_WORKS).then(async () => {
        // Reset selection.
        emit('reset', fileIdsToDownload);
        files.value = [];
      });
    }

    return {
      confirmModal,
      checkAndConfirmDownload,
      download,
      files,
      printers,
    }
  }
}
</script>

<template>
  <div class="d-flex bg-white shadow rounded-custom p-3 flex-column justify-content-between overflow-auto w-100">
    <div class="d-flex flex-column">
      <h4 class="text-center text-uppercase">
        File Selezionati <br>
        ({{ files.value.length }})
      </h4>
      <template v-for="(el, index) in files.value" :key="index">
        <template v-for="(file, index) in el.media" :key="index">
          <div class="text-truncate">
            <img src="@/assets/icons/checkbox-checked.svg" width="30" height="30" alt="checkbox-checked" />
            <span class="mx-2">
              <img v-if="el.bridge" src="@/assets/icons/project/bridge/bridge-sel.svg" height="25" alt="checkbox" />
              {{ file.file_name }}
            </span>
          </div>
        </template>
      </template>
    </div>
    <button type="button" class="btn btn-violet text-uppercase mt-4" @click="checkAndConfirmDownload" :disabled="!files.value.length">
      <img src="@/assets/icons/download.svg" width="20" height="20" alt="download">
      <span class="ms-2">Scarica i file</span>
    </button>
  </div>
  <Confirm
    v-if="confirmModal.isVisible"
    :modal_id="confirmModal.modalId"
    :title="confirmModal.title"
    :subtitle="confirmModal.subtitle"
    :confirm_label="confirmModal.confirmLabel"
    :decline_label="confirmModal.declineLabel"
    :modal_size="'modal-lg'"
    @hidden="() => confirmModal.isVisible = false"
    @confirm="download">
      <RenderAssetsByPrescription :assets="confirmModal.assets" />
    </Confirm>
</template>
