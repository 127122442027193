<template>
  <div class="card rounded-custom border-danger">
    <div class="d-flex justify-content-around p-3">
      <img src="@/assets/icons/danger.svg" width="50" alt="danger" />
      <img src="@/assets/icons/battery-low.svg" width="50" alt="battery-low" />
    </div>
    <div class="p-3 text-center fw-bold">
      <p>Il livello della batteria del Tag è basso pertanto non è possibile associare una prescrizione.</p>
      <p>Scegliere un Tag differente e provvedere con una sostituzione quanto prima.</p>
    </div>
  </div>
</template>
