export default class BarcodeHandler {
    constructor(onBarcodeScanned, timeout = 200) {
      this.buffer = '';
      this.timeout = timeout; // Timeout to clear buffer
      this.onBarcodeScanned = onBarcodeScanned;
      this.clearBufferTimeout = null;
    }

    processKey(event) {
      const key = event.key;
      // Ignore shift, control, alt, meta keys
      if (event.ctrlKey || event.altKey || event.metaKey || key === 'Shift') return;

      if (key === 'Enter') {
        // Barcode is complete, trigger the callback
        if (this.buffer.length > 0) {
          this.onBarcodeScanned(this.buffer.trim());
        }
        this.buffer = ''; // Reset buffer
      } else {
        // Add key to buffer
        this.buffer += key;
      }

      // Reset timeout to clear buffer
      clearTimeout(this.clearBufferTimeout);
      this.clearBufferTimeout = setTimeout(() => {
        this.buffer = ''; // Clear buffer after timeout
      }, this.timeout);
    }
  }
