<template>
  <div class="modal fade" :id="$props.modal_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="$props.modal_size ?? ''">
      <div class="modal-content rounded-3 p-4">
        <div class="p-1 border-0 text-center">
          <h4 class="mb-4 fw-normal" v-html="$props.title"></h4>
        </div>
        <slot></slot>
        <div class="modal-body text-center">
          <p v-html="$props.subtitle"></p>
        </div>
        <div class="modal-footer p-1 border-0 justify-content-center">
          <button type="button" class="btn btn-violet text-uppercase mt-4" @click="event('confirm')" v-html="$props.confirm_label"></button>
          <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="event('decline')" v-html="$props.decline_label"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Modal } from "bootstrap";

export default {
  name: "Confirm",
  props: [
      'modal_id',
      'title',
      'subtitle',
      'confirm_label',
      'decline_label',
      'modal_size'
  ],
  setup(props, context) {
    const toggle = ref(false);

    onMounted(() => {
      showModal(props.modal_id);
      dismissModal(props.modal_id, "hidden")
    })

    // Modal functions -> show/dismiss
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal));
      modal.show();
    }
    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

    const event = async (emitName) => {
      toggle.value = true;
      context.emit(emitName, true);
      let modalId = document.getElementById(props.modal_id);
      const modal = Modal.getInstance(modalId);
      modal.hide();
    }

    return { event }

  }
}
</script>
