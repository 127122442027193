<template>

  <div class="card-container p-3 d-flex flex-column justify-content-center align-items-center" @click="toggle()">

    <div class="card-header d-flex text-white rounded-only-top p-2 align-items-center justify-content-around bg-white">

      <div class="d-flex align-items-center justify-content-center">
        <!-- Double vertical dots icon -->
        <svg xmlns="http://www.w3.org/2000/svg" width="30" class="bi bi-grip-vertical" viewBox="0 0 16 16">
          <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
        <!-- Number text prescription -->
        <p class="text-uppercase fw-bold m-0 fs-3 text-dark">
          {{ $props.box.test_work_queue.prescription_test.prescription.number_text }} - {{ $props.box.test_work_queue.prescription_test.device?.name }}
        </p>
      </div>
      <!-- External link icon -->
      <svg @click="redirectToDashboardInReadOnlyMode($props.box.id)" xmlns="http://www.w3.org/2000/svg" width="20" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
      </svg>
      <!-- <img src="@/assets/icons/plaster/shopping-cart.svg" width="30" alt="shopping-cart"> -->
    </div>

    <div class="card rounded-custom shadow border-0 mb-3 w-100 h-100">
      <div class="d-flex flex-column card-body justify-content-evenly">
        <div class="d-flex justify-content-center align-items-center text-center" style="min-height:3em;overflow:auto;">{{ $props.box.test_work_queue.prescription_test.prescription?.prescription_rows[0]?.name }}</div>
        <hr>
        <div class="mb-4" style="height:3em;overflow:auto;">
          <p class="fw-bold d-inline">Applicazione: </p>
          <small class="me-2">
            <Render
              :data="prescriptionRows"
            />
          </small>
        </div>
        <div class="mb-4">
          <span class="fw-bold">Tipo impianto: </span>
          <small>{{ $props.box.test_work_queue.prescription_test.prescription.plant_type }}</small>
        </div>
        <div class="mb-4" style="height:2em;overflow:auto;"><span class="fw-bold">Nota: </span>
          <small>{{ $props.box.test_work_queue.prescription_test.notes }}</small>
        </div>
        <div class="mb-4" style="height:2em;overflow:auto;"><span class="fw-bold">Paziente: </span>
          <template v-if="$props.box.test_work_queue.prescription_test.prescription.customer">
            <small>
              {{ $props.box.test_work_queue.prescription_test.prescription.customer.first_name }}
              {{ $props.box.test_work_queue.prescription_test.prescription.customer.last_name }}
            </small>
          </template>
        </div>
         <template v-for="child in $props.box.children_test_work_queue_phases" :key="child.id">
            <div class="mb-4" style="height:2em;overflow:auto;"><span class="fw-bold">Rep. successivo: </span>
              <small>{{ child.department.name }}</small>
            </div>
         </template>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <img class="me-3" v-if="! $props.selected" src="@/assets/icons/checkbox.svg" width="40" height="40" alt="checkbox"/>
            <img class="me-3" v-else src="@/assets/icons/checkbox-checked.svg" width="40" height="40" alt="checkbox"/>
            <p class="m-0 fw-bold" :style="{ color: accepted ? '#28A745' : '', color: $props.selected ? '#28A745' : '' }">Lavoro concluso</p>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { ref, onMounted, reactive } from 'vue';
import { useRouter } from "vue-router";

import { groupedByBridgesAndTooth } from "@/use/repositories/prescriptionRows/groupedByBridgesAndTooth";

import Render from "@/components/PrescriptionRows/Render.vue";

export default {
  name: "Card",
  props: [
      'box',
      'selected',
  ],
  components: {
    Render,
  },
  emits: [
      'twqp_selected'
  ],
  setup(props, {emit}) {
    const router = useRouter();
    const accepted = ref(props.selected);

    const prescriptionRows = reactive({
      bridges: [],
      tooth: [],
    });

    const toggle = () => {
      accepted.value = ! accepted.value;
      emit("twqp_selected", props.box.id);
    }

    const syncPrescriptionRows = () => {
      const { bridges, tooth } = groupedByBridgesAndTooth(
        props.box.test_work_queue.prescription_test.prescription.prescription_rows
      );
      prescriptionRows.bridges = bridges;
      prescriptionRows.tooth = tooth;
    };

    onMounted(() => {
      syncPrescriptionRows();
    });

    const redirectToDashboardInReadOnlyMode = async (testWorkQueuePhaseId) => {
      return await router.push({
        name: "Dashboard",
        params: {
          test_work_queue_phases_id: testWorkQueuePhaseId,
        },
      });
    }

    return {
      accepted,
      toggle,
      redirectToDashboardInReadOnlyMode,
      prescriptionRows,
    }
  }
}
</script>

<style scoped>
.card-header {
  width: 70%;
}
.card {
  font-size: 1.4em;
}
.card-container {
  min-width: 25em;
  max-width: 25em;
  height: 100%;
}
.card-container:hover,
.link-to:hover {
  cursor: pointer;
}
.drag {
  cursor: move;
}
</style>
