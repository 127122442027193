import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAllRejectedByState(stateSlug) {
  const axios = axiosInstance().primoLab;
  let prescriptionsRejected = [];

  await axios
    .get(`/prescription-rejections`,  {
      params: {
        state_slug: stateSlug,
        action: "by_slug"
      },
    })
    .then(async (response) => {
      prescriptionsRejected = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionsRejected };
}
